import { MatPaginatorIntl } from '@angular/material/paginator';

const rangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `共 ${length} 个订单`; }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

  return `当前显示 ${startIndex + 1} - ${endIndex} , 共 ${length} 个订单`;
}


export function getPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();
  
  paginatorIntl.itemsPerPageLabel = '';
  paginatorIntl.firstPageLabel = '';
  paginatorIntl.lastPageLabel = '';
  paginatorIntl.nextPageLabel = '';
  paginatorIntl.previousPageLabel = '';
  paginatorIntl.getRangeLabel = rangeLabel;
  
  return paginatorIntl;
}