import {AfterContentInit, Component, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

import { DashboardService } from '../dashboard.service'
import { Order } from '../dashboard.interface'

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements AfterContentInit  {
  displayedColumns: string[] = ['id', 'file_customer', 'datetime_joined', 'catalog',  'urgency', 'status', 'date_end', 'expense','file_download'];
  dataSource: MatTableDataSource<Order>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private _snackBar: MatSnackBar, private _dbservice: DashboardService) {
  }

  ngAfterContentInit (): void {
    this.init_orders(JSON.parse(localStorage.getItem("orders")));
  }

  ngAfterViewInit(): void{ 
    if (this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }

  init_orders (orders: MatTableDataSource<Order>): void {
    if (orders){
      this.dataSource = new MatTableDataSource(orders['orders']);
    }
  }

  update_order_status (id_order: string): void {
    this._dbservice.order_update_status_download(id_order).subscribe();
  }
}
