import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap,  } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    isRefreshing: boolean = false;

    constructor(private _authService: AuthService, private _router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req.clone();

        if (!!localStorage.getItem("access")) {
            authReq = req.clone({ setHeaders: { Authorization: "Bearer " + localStorage.getItem("access")}});
        }

        return next.handle(authReq).pipe(catchError(error => {
            
            if (error instanceof HttpErrorResponse && error.status === 401 ) {
                if (error['error']['code'] == "user_inactive"){
                    localStorage.clear();
                    this._router.navigate(['/signin']);
                    return throwError(error);
                }
                else{
                    return this.handle401Error(req, next);
                }
            }
            else {
                return throwError(error);
            }
        }));
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        // if not in refreshing process -> refresh token
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            return this._authService.jwt_refresh(localStorage.getItem("refresh")).pipe(
                switchMap(
                    result => {
                        this.isRefreshing = false;
                        localStorage.setItem("access", result['access']);
                        const authReq_new = req.clone({ setHeaders: { Authorization: "Bearer " + localStorage.getItem("access")}});
                        return  next.handle(authReq_new)
                    }
                ),
                catchError(
                    error => {
                        if (error instanceof HttpErrorResponse && error.status === 401 ) {
                            localStorage.clear();
                            this._router.navigate(['/signin']);
                            return throwError(error);
                        }
                        else {
                            return throwError(error);
                        }
                    }
                )
            )
        }
        // if in refreshing process -> stop
        else {
            localStorage.clear();
            this._router.navigate(['/signin']);
            return next.handle(req)
        }
        
    }

}