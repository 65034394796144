<div class="ctr-1">
    <div class="ctr-2" id="ctr-2-1">
        <div class="ctr-3" id="ctr-3-1">
            <span>登录</span>
            <span>你的个人帐号</span>
        </div>
        <div class="ctr-3" id="ctr-3-2" [formGroup]="signinFormGroup">
            <div class="ctr-4" id="ctr-4-1">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>Email</mat-label>
                        <input #input_email matInput placeholder="请输入登录邮箱" formControlName="email" (keyup.enter)="$event.target.blur(); signin()">
                        <mat-error *ngIf="signinFormGroup.invalid"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="ctr-4" id="ctr-4-2">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>Password</mat-label>
                        <input #input_pw matInput placeholder="请输入登录密码" type="password" formControlName="password" (keyup.enter)="$event.target.blur(); signin()">
                        <mat-error *ngIf="signinFormGroup.invalid"></mat-error>
                    </mat-form-field>
                </div>
                <div class="ctr-5" id="ctr-5-3">
                    <a [routerLink]="['../reset-password']">忘记密码？</a>
                </div>
            </div>
        </div>
        <div class="ctr-3" id="ctr-3-3">
            <div class="ctr-4" id="ctr-4-1" (click)="signin()">
                <span class="text">Login</span>
                <span class="material-icons">login</span>
            </div>
        </div>
    </div>
    <div class="ctr-2" id="ctr-2-2">
        <span>没有账户？<a [routerLink]="['../signup']">注册</a></span>
    </div>
</div>