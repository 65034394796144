<div class="ctr" id="ctr-loading" *ngIf="is_loading">
    <div class="blobs">
        <div class="blob-center"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
        <div class="blob"></div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
          <feBlend in="SourceGraphic" in2="goo" />
          </filter>
      </defs>
    </svg>
    <span>加载中...</span>
</div>

<div class="ctr" id="ctr-dashboard"  *ngIf="!is_loading">
    <div class="ctr-1" id="ctr-1-sidenav" [style.width]="is_toggled? '13vw': '5vw'">
        <div class="ctr-2" id="ctr-2-logo">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/logo/logo_blue.svg" [style.width]="is_toggled? '6vw': '3vw'" [routerLink]="['..']">
        </div>
        <div class="ctr-2" id="ctr-2-menu">
            <div class="ctr-3" [routerLink]="['./overview']" routerLinkActive="ctr-3-active" [routerLinkActiveOptions]="{ exact: false }">
                <span class="material-icons-outlined">home</span>
                <span class="text" *ngIf="is_toggled" [@enterAnimation]>开始</span>
            </div>
            <div class="ctr-3" [routerLink]="['./upload']" routerLinkActive="ctr-3-active">
                <span class="material-icons-outlined">local_grocery_store</span>
                <span class="text" *ngIf="is_toggled" [@enterAnimation]>创建订单</span>
            </div>
            <div class="ctr-3" [routerLink]="['./orders']" routerLinkActive="ctr-3-active">
                <span class="material-icons-outlined">analytics</span>
                <span class="text" *ngIf="is_toggled" [@enterAnimation]>订单管理</span>
            </div>
            <div class="ctr-3" [routerLink]="['./settings']" routerLinkActive="ctr-3-active">
                <span class="material-icons-outlined">settings</span>
                <span class="text" *ngIf="is_toggled" [@enterAnimation]>用户设置</span>
            </div>
        </div>
        <div class="ctr-2" id="ctr-2-footer">
            <div class="ctr-3" (click)="logout()">
                <span class="material-icons-outlined">power_settings_new</span>
                <span class="text" *ngIf="is_toggled" [@enterAnimation]>退出登录</span>
            </div>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-button">
        <div>
            <span class="material-icons-outlined" *ngIf="is_toggled" (click)="is_toggled = !is_toggled">more_vert</span>
            <span class="material-icons-outlined" *ngIf="!is_toggled" (click)="is_toggled = !is_toggled">menu</span>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-toolbar">
        <img [src]="avatar"
        [routerLink]="['./settings']"  [matTooltip]="email" matTooltipPosition="left" matTooltipClass="tooltip">
    </div>
    <div class="ctr-1" id="ctr-1-content">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
</div>