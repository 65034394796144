<div id="ctr">
    <div id="ctr-info">
        <p>
            <b>
                注意事项，请仔细阅读:
            </b> 
        </p>
        <p>
            账号注销后，您将不能继续使用REKO提供的服务(包括不限于: 用户登录，数据上传，文档下载等)。
            在一段时间内我们将继续为您提供数据恢复功能，您可以在三个月内随时通过客服重新激活账号。三个月后，您的账号将被永久删除。
        </p>
        <p>
            <b>重要提示：</b>账号删除后，您的所有相关数据都将被永久删除，账号无法被再次激活。
        </p>
    </div>
    <div id="ctr-1">
        <button mat-raised-button mat-dialog-close class="btn" id="btn-close">取消</button>
        <button mat-raised-button mat-dialog-close class="btn" id="btn-confirm" (click)="deactivate_user()">同意，注销账号</button>
    </div>
</div>