<div class="ctr-1" *ngIf="!is_vaild">
    <div class="ctr-2" id="ctr-2-1">
        <div class="ctr-3" id="ctr-3-1">
            <span>忘记密码</span>
            <span>重置一个新密码</span>
        </div>
        <div class="ctr-3" id="ctr-3-2" [formGroup]="resetFormGroup">
            <div class="ctr-4" id="ctr-4-1">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="请输入登录邮箱" formControlName="email" (keyup.enter)="verify_email()">
                        <mat-error *ngIf="resetFormGroup.invalid"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="ctr-4" id="ctr-4-2">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>New Password</mat-label>
                        <input matInput placeholder="请输入新密码 (至少6位)" type="password" formControlName="password" (change)="check_password_confirm()" (keyup.enter)="$event.target.blur(); verify_email()">
                        <mat-error *ngIf="resetFormGroup.invalid"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="ctr-4" id="ctr-4-3">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>New Password Confirm</mat-label>
                        <input matInput placeholder="请确认新密码 (至少6位)" type="password" formControlName="password_confirm" (change)="check_password_confirm()" (keyup.enter)="$event.target.blur(); verify_email()">
                        <mat-error *ngIf="resetFormGroup.invalid"></mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="ctr-3" id="ctr-3-3">
            <div class="ctr-4" id="ctr-4-1" (click)="verify_email()">
                <span class="text">Confirm</span>
                <span class="material-icons">login</span>
            </div>
        </div>
    </div>
    <div class="ctr-2" id="ctr-2-2">
        <span>已有账户？<a [routerLink]="['../signin']">登录</a></span>
    </div>
</div>


<div class="ctr-1" *ngIf="is_vaild">
    <div class="ctr-2" id="ctr-2-1">
        <div class="ctr-3" id="ctr-3-1">
            <span>激活</span>
            <span>新密码激活</span>
        </div>
        <div class="ctr-3" id="ctr-3-2">
            <div class="ctr-4" id="ctr-4-1">
                <div class="ctr-5" id="ctr-5-1"></div>
                <div class="ctr-5" id="ctr-5-2">
                    <mat-form-field appearance="none">
                        <mat-label>Code</mat-label>
                        <input matInput placeholder="请输入6位激活码" [formControl]="codeFormControl" (keyup.enter)="$event.target.blur(); code_activate()">
                        <mat-error *ngIf="codeFormControl.invalid"></mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="ctr-3" id="ctr-3-3">
            <div class="ctr-4" id="ctr-4-1" (click)="code_activate()">
                <span class="text">Activate</span>
                <span class="material-icons">login</span>
            </div>
        </div>
    </div>
    <div class="ctr-2" id="ctr-2-2">
        <span>已有账户？<a [routerLink]="['../signin']">登录</a></span>
    </div>
</div>


