import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend  } from '@angular/common/http';
import { Observable, of  } from 'rxjs';

import { ConfigModule } from '../app-config'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  config = new ConfigModule();
  url_jwt_access = this.config.be_ip + 'jwt_access/'
  url_jwt_refresh = this.config.be_ip + 'jwt_refresh/'
  url_jwt_verify = this.config.be_ip + 'jwt_verify/'
  url_verify = this.config.be_ip + 'verify/'

  // http request in this service work without interceptor
  private _http: HttpClient;

  constructor( handler: HttpBackend) { 
    this._http = new HttpClient(handler);
  }

  get_token(data: any): Observable<any> {
    return this._http.post(this.url_jwt_access, data)
  }

  jwt_verify(token: string): Observable<any> {
    return this._http.post(this.url_jwt_verify, {'token': token})
  }

  jwt_refresh(refresh_token: string): Observable<any> {
    return this._http.post(this.url_jwt_refresh, {'refresh': refresh_token})
  }

  verify_email(data:  any): Observable<any> {
    return this._http.post(this.url_verify, data)
  }
  
  activate_code(id: string, data): Observable<any> {
    return this._http.post(this.url_verify + id + '/activate_code/', data)
  }
}
