import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './home/navbar/navbar.component';
import { FooterComponent } from './home/footer/footer.component';
import { ContentHomeComponent } from './home/content-home/content-home.component';
import { ContentExampleComponent } from './home/content-example/content-example.component';
import { ContentImpressumComponent } from './home/content-impressum/content-impressum.component';
import { ContentQaComponent } from './home/content-qa/content-qa.component';
import { ContentIeComponent } from './home/content-ie/content-ie.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthComponent } from './auth/auth.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ResetComponent } from './auth/reset/reset.component';
import { BrowserGuard } from './browser.guard'
import { AuthGuard } from './auth/auth.guard';
import { UnAuthGuard } from './auth/unauth.guard';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatExpansionModule} from '@angular/material/expansion';





import { OverviewComponent } from './dashboard/overview/overview.component';
import { UploadComponent } from './dashboard/upload/upload.component';
import { OrdersComponent } from './dashboard/orders/orders.component';
import { SettingsComponent, DialogDeactivationConfirmationComponent } from './dashboard/settings/settings.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { WeatherDescPipe, OrderUrgencyPipe, OrderCatalogPipe, OrderStatusPipe } from './dashboard/dashboard.pipe';
import { getPaginatorIntl } from './dashboard/dashboard.paginator-intl';
import { DragdropDirective } from './dashboard/upload/dragdrop.directive';
import { DialogConfirmComponent } from './dashboard/upload/dialog-confirm/dialog-confirm.component';
import { DialogPaymentComponent } from './dashboard/upload/dialog-payment/dialog-payment.component';
import { DialogCreationComponent } from './dashboard/upload/dialog-creation/dialog-creation.component';
import { ContentDatenschutzComponent } from './home/content-datenschutz/content-datenschutz.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    NavbarComponent,
    FooterComponent,
    ContentHomeComponent,
    ContentExampleComponent,
    ContentImpressumComponent,
    ContentQaComponent,
    AuthComponent,
    SigninComponent,
    SignupComponent,
    ResetComponent,
    OverviewComponent,
    UploadComponent,
    OrdersComponent,
    SettingsComponent,
    WeatherDescPipe,
    OrderUrgencyPipe,
    OrderCatalogPipe,
    OrderStatusPipe,
    DragdropDirective,
    DialogConfirmComponent,
    DialogPaymentComponent,
    DialogCreationComponent,
    DialogDeactivationConfirmationComponent,
    ContentIeComponent,
    ContentDatenschutzComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatExpansionModule
  ],
  providers: [AuthGuard, UnAuthGuard, BrowserGuard,
              {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
              {provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
