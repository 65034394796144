import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators} from '@angular/forms';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';

import { DashboardService } from '../dashboard.service'


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  is_accepted: boolean = false;
  color_span: string = "red";

  @Output() infoUpdateEvent = new EventEmitter();
  
  settingsFormGroup = new FormGroup({
    gender: new FormControl('', []),
    last_name: new FormControl('', [Validators.maxLength(10)]),
    first_name: new FormControl('', [Validators.maxLength(10)]),
    phone_number: new FormControl('', [Validators.pattern("^((\\+49?))?[0-9]{9,12}$")])
  })

  restpwFormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
    password_new: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
    password_confirm: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(30)])
  })

  constructor( private _dbService: DashboardService, private _snackBar: MatSnackBar, private _router: Router, private _dialog: MatDialog) { }


  ngOnInit(): void {
    this.settingsFormGroup.get("gender").setValue(JSON.parse(localStorage.getItem("user_info"))['gender']);
    this.settingsFormGroup.get("last_name").setValue(JSON.parse(localStorage.getItem("user_info"))['last_name']);
    this.settingsFormGroup.get("first_name").setValue(JSON.parse(localStorage.getItem("user_info"))['first_name']);
    this.settingsFormGroup.get("phone_number").setValue(JSON.parse(localStorage.getItem("user_info"))['phone_number']);
  }

  // style binding for the invalid formcontrols
  style_error_settingspwFG(formcontrol: string): string {
    if (this.settingsFormGroup.get(formcontrol).invalid && this.settingsFormGroup.get(formcontrol).touched){
      return 'background-color: #da31318e'
    }
    else {
      return 'background-color: #eff0f38e'
    }
  }

  // style binding for the invalid formcontrols
  style_error_restpwFG(formcontrol: string): string {
    if (this.restpwFormGroup.get(formcontrol).invalid && this.restpwFormGroup.get(formcontrol).touched){
      return 'background-color: #da31318e'
    }
    else {
      return 'background-color: #eff0f38e'
    }
  }


  update_customer_data(): void {
    this.settingsFormGroup.markAllAsTouched();
    if (this.settingsFormGroup.valid) {
      let data_request:any = {
        'gender': this.settingsFormGroup.get('gender').value,
        'last_name': this.settingsFormGroup.get('last_name').value,
        'first_name': this.settingsFormGroup.get('first_name').value,
        'phone_number': this.settingsFormGroup.get('phone_number').value,
      }
      this._dbService.update_customer_info(localStorage.getItem("customer_id"), data_request).subscribe(
        result => {
          localStorage.setItem("user_info", JSON.stringify(data_request));
          this._snackBar.open(result['detail'], '', {duration: 3000,  panelClass :['snackbar-success']});
          this.settingsFormGroup.markAsUntouched();
          this.infoUpdateEvent.emit();
        },
        error => {
          this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
        }
      )
    }
  } 
  
  check_password_confirm(): void{
    if (this.restpwFormGroup.get("password_new").valid && (this.restpwFormGroup.get("password_new").value != this.restpwFormGroup.get("password_confirm").value)) {
      this.restpwFormGroup.get('password_confirm').setErrors({'incorrect': true});
    }
    else if (this.restpwFormGroup.get("password_new").valid && (this.restpwFormGroup.get("password_new").value == this.restpwFormGroup.get("password_confirm").value)) {
      this.restpwFormGroup.get('password_confirm').setErrors(null);
    }
  }


  update_password(): void {
    this.restpwFormGroup.markAllAsTouched();
    if (this.restpwFormGroup.valid) {
      let data_request:any = {
        'password': this.restpwFormGroup.get('password').value,
        'password_new': this.restpwFormGroup.get('password_new').value,
      }
      this._dbService.update_password(localStorage.getItem("user_id"), data_request).subscribe(
        result => {
          this._snackBar.open(result['detail'], '', {duration: 3000,  panelClass :['snackbar-success']});
          this.restpwFormGroup.get('password').setValue("");
          this.restpwFormGroup.get('password_new').setValue("");
          this.restpwFormGroup.get('password_confirm').setValue("");
          this.restpwFormGroup.markAsUntouched();
        },
        error => {
          this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
        }
      );
      
    }
  } 
  
  deactivate_user(): void {
    const dialogRef = this._dialog.open(DialogDeactivationConfirmationComponent, { panelClass: 'custom-dialog-container', autoFocus: false, });
    dialogRef.afterClosed().subscribe(() => this.is_accepted=false);

  } 
}

@Component({
  selector: 'dialog-deactivation-confirm',
  templateUrl: './dialog-deactivation-confirm.html',
  styleUrls: ['./dialog-deactivation-confirm.scss']
})
export class DialogDeactivationConfirmationComponent {

  constructor( private _dbService: DashboardService, private _snackBar: MatSnackBar, private _router: Router, private _dialog: MatDialog) { }
  
  deactivate_user(): void {
      this._dbService.deactivate_user(localStorage.getItem("user_id")).subscribe(
        result => {
          this._snackBar.open(result['detail'], '', {duration: 3000,  panelClass :['snackbar-success']});
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          localStorage.removeItem("user_id");
          localStorage.removeItem("customer_id");
          this._router.navigate(['../signin',]);
        },
        error => this._snackBar.open("账户注销失败，请稍后重试", '', {duration: 3000,  panelClass :['snackbar-error']}),
      )
  }
}
