import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { DashboardService } from '../../dashboard.service'

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

import { DialogCreationComponent } from '../dialog-creation/dialog-creation.component'


declare var paypal;

@Component({
  selector: 'app-dialog-payment',
  templateUrl: './dialog-payment.component.html',
  styleUrls: ['./dialog-payment.component.scss']
})
export class DialogPaymentComponent implements OnInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;
  email:string = localStorage.getItem("email");
  id_coupon: string;
  code_coupon: string;
  amount_gross: number;
  message_customer: string;
  
  interval:any;
  time_expired: number = 10 * 60;
  str_time_expired:string = '10:00';
  
  dialogUpdate: MatDialogRef<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public preorder, public dialogRef: MatDialogRef<DialogPaymentComponent>, private _snackBar: MatSnackBar, private _dbService: DashboardService, private _router: Router, public _dialog: MatDialog) { }

  ngOnInit(): void {
    console.log(this.preorder);
    this.amount_gross = this.preorder.amount_customer;
    this.message_customer = "";
    this.id_coupon = "";

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    })
    
    this.interval = setInterval(() => {
      this.checkExpiration();
      }, 1000);

    paypal.Buttons({
      style: {
          shape: 'pill',
          color: 'blue',
          layout: 'vertical',
          label: 'paypal',
      },
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: "Korrekturlesen-Dienstleistung",
              amount: {
                currency_code: 'EUR',
                value: this.amount_gross
              }
            }
          ]
        });
      },
      onApprove: async(data, actions) => {
        this.time_expired =  10 * 60;
        this.dialogUpdate = this._dialog.open(DialogCreationComponent, { panelClass: 'custom-dialog-container', disableClose: true});
        // capture order info from paypal api
        const details = await actions.order.capture();
        
        await this.create_transaction_customer(details);
        await this.create_order(details['id']);
        await this.get_orders();
      
        this.dialogUpdate.close();
        this.dialogRef.close('successed');   
      },

      onError: err => {
        console.log(err);
      },

      onCancel: async(data, actions) => {
        alert('订单将在'+ Math.floor(this.time_expired/60) +'分'+ (Math.floor(this.time_expired%60) / Math.pow(10,2)).toFixed(2).substr(2) + '秒后过期，付款页面将自动关闭');
      }
    }).render(this.paypalElement.nativeElement)
  } 
  
  ngOnDestroy() {
    this._dbService.deactivate_preorder(this.preorder.id).subscribe();
    clearInterval(this.interval);
  }

  checkExpiration(){
    this.time_expired--;
    this.str_time_expired = Math.floor(this.time_expired/60) + ':' + (Math.floor(this.time_expired%60) / Math.pow(10,2)).toFixed(2).substr(2);
    if (this.time_expired == 0) {
      this.dialogRef.close();
      this._snackBar.open("付款流程已超时，请重新验证订单", '', {duration: 8000,  panelClass :['snackbar-error']});
    }
  }

  check_coupon_availability() {
    let data_request:any = {
      'code': this.code_coupon
    }

    this._dbService.check_coupon_availability(data_request).subscribe(
      result => {
        this.id_coupon = result['id']
        this.amount_gross = Math.round((this.preorder.amount_customer * result['discount'] + Number.EPSILON) * 100) / 100
      },
      error => {
        this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
      }
    )
  }

  async create_order(id_transaction_customer: string) {
    const data_request = new FormData();
    data_request.append('id_preorder', this.preorder.id);
    data_request.append('id_coupon', this.id_coupon);
    data_request.append('message_customer', this.message_customer);
    data_request.append('id_transaction_customer', id_transaction_customer);

    const response = await this._dbService.create_order(data_request);
    this._dbService.order_update_transaction_cx(response['id']).subscribe( 
      result => {
        this._dbService.order_send_confirmation_payment_received(response['id']).subscribe();
      },
      error => {
        this._dbService.order_send_confirmation_payment_received(response['id']).subscribe();
      }
    );
  }
  
  async create_transaction_customer(deatils: any) {
    const data_request = {
     'id': deatils['id']
    };
    
    await this._dbService.create_transaction_customer(data_request);
  }

  async get_orders() {
    let orders =  await this._dbService.get_orders(localStorage.getItem("customer_id"));
    localStorage.setItem("orders",  JSON.stringify(orders));
  }
}


