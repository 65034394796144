import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { Router} from "@angular/router"
import { DashboardService } from "./dashboard.service"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('200ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
      ]
    )
  ],
})

export class DashboardComponent implements OnInit {
  is_loading: boolean = true;
  is_toggled: boolean = true;
  email: string; 
  avatar: string;

  constructor( private _router: Router, private _dbService: DashboardService) { }

  async ngOnInit() {
    this.email = localStorage.getItem('email');

    let user_id = await this._dbService.get_user_id();
    localStorage.setItem("user_id", user_id['id']);

    let customer_id = await this._dbService.get_customer_id();
    localStorage.setItem("customer_id", customer_id['id']);

    // weather

    let info_weather =  await this._dbService.systembroadcast_get_weather();
    localStorage.setItem("weather",  JSON.stringify(info_weather));
    
    // system Info    (Version)
    // User Broadcast (Message)
    
    // Orders
    let orders =  await this._dbService.get_orders(localStorage.getItem("customer_id"));
    localStorage.setItem("orders",  JSON.stringify(orders));

    // User_Info
    let user_info = await this._dbService.get_customer_info(localStorage.getItem("customer_id"));
    localStorage.setItem("user_info", JSON.stringify(user_info));
    this.map_avater();

    this.is_loading=false;
  }
  
  
  logout(): void {
    localStorage.clear();
    localStorage.removeItem("__paypal_storage__");
    this._router.navigate(['../signin',]);
  }

  map_avater(): void {
    if (JSON.parse(localStorage.getItem("user_info"))['gender'] == 2) {
      this.avatar = "https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/2.svg";
    }
    else {
      this.avatar = "https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/1.svg";
    }
  }

  onActivate(componentReference) {
    if (this._router.url.indexOf('settings') !== -1) {
      componentReference.infoUpdateEvent.subscribe(() => {
        this.map_avater();
     })
    }
 }
}
