<img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/example/bg_1.png?time=${Date.now()}" id="bg-title">

<div id='ctr-1'>
    <span id='title-1'>参考示例</span>
    <span id='title-2'>Example</span>
    <span id='title-3'>通过示例您可以更好的了解REKO的德语服务</span>
</div>

<div id='ctr-2'>
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <div id="ctr-2-title">
            <h1>示例：校对文档</h1>
            <h2>我们修正校对各类德语使用的错误，让您的文档的更具可读性</h2>
            <h2>鼠标移至深色背景区域，可查看更多建议或提示</h2>
        </div>

        <div id="ctr-2-example">
            <img id="ctr-2-word">
            <p>
                <span class="title"><strong>Anwendungsmöglichkeiten der Lebensmittelbestrahlung</strong></span><br>
                <span class="title">2.4.1  Vorteile und Einschränkung<span class="under">en</span>
                <span>&nbsp;</span>
                <span class="tip" matTooltip="风格校对: 用第二格Genitiv来表示所属关系更符合德国人的写作习惯。" matTooltipPosition="above">    
                    <span class="under">der</span><span class="through">von</span>
                </span>
                Lebensmittelbestrahlung</span><br>
                <span class="title">2.4.1.1  Vorteile
                <span class="under">der</span><span class="through">von</span>
                Lebensmittelbestrahlung</span><br>

                Die Lebensmittelindustrie verwendet verschiedene Methoden zur Haltbarkeitsverlängerung und zur Veredelung von Lebensmitteln, 
                wie z. B.  
                <span class="tip" matTooltip="风格校对: 在列举事物时，通常情况下第一件被列举事物前要有冠词修饰。" matTooltipPosition="above">    
                    <span class="under">das</span> 
                </span>
                Kühlen, Einfrieren, Dehydrieren, Fermentieren und Hinzufügen chemischer Konservierungsmittel.
                <span class="tip" matTooltip='风格校对: "Es gibt" 一般用于日常口语中，故此处用 "Dabei auftreten" 进行替换，使文章更正式。' matTooltipPosition="above">    
                    <span class="under">Dabei treten</span><span>&nbsp;</span><span class="through">Es gibt</span>
                </span>
                immer einige unerwünschte und unvermeidbare Veränderungen 
                <span class="under">i</span><span class="through">a</span>n 
                <span class="tip" matTooltip='语法校对: 在介词 "in" 后面，通常情况下要使用第三格Dativ。' matTooltipPosition="above">    
                    de<span class="under">n</span><span class="through">r</span>
                </span>
                Lebensmitteln <span class="under">auf</span>, die häufig mit
                <span class="tip" matTooltip='语法&逻辑校对: 由于在前半句使用了复数 "Lebensmitteln"，故在从句中也应继续使用复数对其修饰。' matTooltipPosition="left">    
                    <span class="under">den</span> physikalisch<span class="under">en</span>, 
                    chemisch<span class="under">en</span>
                    und enzymatisch<span class="under">en</span> Eigenschaft<span class="under">en</span>
                </span>
                <span>&nbsp;</span>
                <span class="under">der</span><span class="through">von</span> Lebensmittel verbunden sind.
                Beispielweise kann 
                <span class="tip" matTooltip='风格校对: 添加的部分使语句更符合德国人的写作方式。' matTooltipPosition="above">    
                    <span class="under">man durch die</span>
                </span> 
                Wärmebehandlung zwar krankheitserregende Bakterien eliminieren, 
                <span class="tip" matTooltip='语法校对: 语句缺少主语。' matTooltipPosition="above">    
                    <span class="under">sie</span>
                </span> 
                führt jedoch auch zu einem Nährstoffverlust 
                <span class="tip" matTooltip='逻辑校对: "Nährstoffverlust" 并不是 "Abbau von Vitaminen"，而是其的后果，故此处进行逻辑上了修改。' matTooltipPosition="above">    
                    <span class="under">durch den</span><span>&nbsp;</span><span class="through">wie der</span>
                </span>
                Abbau von Vitaminen und einer Beeinträchtigung des Geschmacks 
                <span class="under">der</span><span class="through">von</span>
                Lebensmitteln. Mit der Lebensmittelbestrahlung steht ein Verfahren zur Verfügung, das nicht nur das Wachstum von Mikroorganismen hemmen, 
                sondern auch die unerwünschten Veränderungen in Lebensmitteln so 
                <span class="tip" matTooltip='选词优化: "klein" 一般用来形容事物，表示程度则应使用 "gring"。' matTooltipPosition="above">    
                    <span class="under">gering</span><span>&nbsp;</span><span class="through">klein</span>
                </span>
                wie möglich halten
                <span class="tip" matTooltip='语法校对: 句中使用了如 "hemmen", "halten" 等动词原形，故句尾应添加相应的情态动词Modalverb。' matTooltipPosition="above">    
                    <span class="under">kann</span>.
                </span><br>
                <span class="tip" matTooltip='风格校对: 德语中很少直接以名词作为句子的开头，一般情况下要添加冠词。' matTooltipPosition="above">    
                    <span class="under">Die</span>
                </span>
                Bestrahlung ist 
                <span class="tip" matTooltip='句式结构调整: 将句子从 "in der Lebensmittelverarbeitung ein besonders schonendes Verfahren" 
                改为了 "ein besonders schonendes Verfahren der Lebensmittelverarbeitung" 更符合德国人的表达方式。' matTooltipPosition="above">    
                    <span class="under">ein besonders schonendes Verfahren</span>
                    <span>&nbsp;</span>
                    <span class="through">in</span>
                </span>
                der Lebensmittelverarbeitung 
                <span class="through">ein besonders schonendes Verfahren.</span>
                <span class="tip" matTooltip='句式结构调整: 将两组以相同主语开头的句子用 "und" 合并成一句，使文章更简洁。' matTooltipPosition="above">
                    <span class="under">und</span><span class="through">Sie</span>
                </span>
                wird auch als 
                <span class="tip" matTooltip='语法校对: 由于 "kalt" 是用来形容 "Pasteurisierung " 的形容词，故此处将错误使用的合成词
                "Kaltpasteurisierung"
                改写成
                "kalte Pasteurisierung"。' matTooltipPosition="above">
                    <span class="under">kalte</span>
                    <span>&nbsp;</span>
                    <span class="through">Kalt</span>
                    <span class="under">P</span>
                    <span class="through">p</span>
                </span>asteurisierung 
                bezeichnet
                <span class="tip" matTooltip='句式结构调整: "Weil" 开头的句子是一定是从句，不能单独作为一句话来使用，故作此修改。' matTooltipPosition="above">    
                    <span class="under">, w</span>
                    <span class="through">.W</span>eil
                </span>
                die durch Bestrahlung verursachte Temperaturänderung im Inneren de<span class="under">r</span><span class="through">s</span>
                Lebensmittel<span class="through">s</span> sehr gering ist. 
                Sie bietet 
                <span class="tip" matTooltip='风格校对: 将在句尾的 "auch" 放到句中，更符合德国人的表达习惯。' matTooltipPosition="above">    
                    <span class="under">auch</span>
                </span>
                eine Möglichkeit <span class="through">auch</span>,
                Lebensmittel bei Raumtemperatur oder sogar im tiefgefrorenen Zustand zu pasteurisieren oder zu sterilisieren. 
                </p>


            <a href="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/example/Example_REKO.docx">
                <span class="material-icons">downloading</span>
            </a>
            
        </div>

    </div>
    <div id="ctr-13">
        <div class="ctr-wrapper">
            <span id="title-1">做最完美的事</span>
            <span id="title-2">想要尽快获得更多资讯，开启你的文本润色之旅，
                以及完整的用户体验，点击快速注册，数据安全，绿色无广告
            </span>
            <div [routerLink]="['/signup']">
                <span>快速注册</span>
            </div>
        </div>
    </div>
</div>
