<div class="ctr">
    <div class="ctr-1" id="ctr-1-info">
        <div id="ctr-2-title">
            <h1>订单信息</h1>
        </div>
        <div id="ctr-2-order">
            <span>创建日期</span>
            <span>订单类型</span>
            <span>{{preorder.word_counts == null? '订单字数': '订单页数'}}</span>
            <span style="font-weight: 600;">加急选项</span>
            <span style="font-weight: 600;">交付日期</span>
        </div>
        <div id="ctr-2-ordervalue">
            <span>{{preorder.datetime_joined}}</span>
            <span>{{preorder.catalog | OrderCatalogPipe}}</span>
            <span>{{preorder.word_counts == null? preorder.count_words: preorder.count_pages}}</span>
            <span style="font-weight: 600;">{{preorder.urgency | OrderUrgencyPipe}}</span>
            <span style="font-weight: 600;">{{preorder.date_end}}</span>
        </div>
        <div id="ctr-2-code">
            <input type="text" placeholder="优惠码" [(ngModel)]="code_coupon" [disabled]="(id_coupon != '')">
            <button [class]="(!code_coupon || (id_coupon != '')) ? 'btn-disabled' : ''" mat-raised-button (click)="check_coupon_availability()" [disabled]="!code_coupon || (id_coupon != '')">确认</button>
        </div>
        <div id="ctr-2-preiskey">
            <span>合计金额</span>
        </div>
        <div id="ctr-2-preisvalue">
            <span *ngIf='!id_coupon'>{{preorder.amount_customer | number : '1.2-2'}}€</span>
            <span *ngIf='id_coupon'><s>{{preorder.amount_customer | number : '1.2-2'}}€</s> {{amount_gross | number : '1.2-2'}}€</span>
        </div>
        <div id="ctr-2-msg">
            <textarea placeholder="特殊要求，请用德语在此留言 (交付时间为交付日期当日19点前，不可提前)" [(ngModel)]="message_customer" maxlength="100"></textarea>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-payment">
        <div id="ctr-2-time">
            <span>{{str_time_expired}}</span>
        </div>
        <div id="ctr-2-info">
            <span>订单编号: {{preorder.id}}</span>
            <span>邮箱地址: {{email}}</span>
        </div>
        <div id="ctr-2-paypal">
            <h1>快速支付</h1>
            <span>所有支付方式都是安全和加密的，另请注意数据保护规定。</span>
            <div #paypal id="paypal-plugin"></div>
        </div>
    </div>
</div>