import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { UnAuthGuard } from './auth/unauth.guard';
import { BrowserGuard } from './browser.guard'
import { HomeComponent } from './home/home.component';
import { ContentHomeComponent } from './home/content-home/content-home.component';
import { ContentIeComponent } from './home/content-ie/content-ie.component'
import { ContentExampleComponent } from './home/content-example/content-example.component';
import { ContentImpressumComponent } from './home/content-impressum/content-impressum.component';
import { ContentQaComponent } from './home/content-qa/content-qa.component';
import { ContentDatenschutzComponent } from './home/content-datenschutz/content-datenschutz.component';
import { AuthComponent } from './auth/auth.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ResetComponent } from './auth/reset/reset.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverviewComponent } from './dashboard/overview/overview.component';
import { UploadComponent } from './dashboard/upload/upload.component';
import { OrdersComponent } from './dashboard/orders/orders.component';
import { SettingsComponent } from './dashboard/settings/settings.component';


const routes: Routes = [
  { path: '', component: HomeComponent, 
    children:[
      {path: '', component: ContentHomeComponent, canActivate: [BrowserGuard],},
      {path: 'no-support', component: ContentIeComponent, },
      {path: 'q&a', component: ContentQaComponent, canActivate: [BrowserGuard],},
      {path: 'example', component: ContentExampleComponent, canActivate: [BrowserGuard],},
      {path: 'impressum', component: ContentImpressumComponent,  canActivate: [BrowserGuard],},
      {path: 'datenschutz', component: ContentDatenschutzComponent,  canActivate: [BrowserGuard],},
    ] },
  { path: '', component: AuthComponent, canActivate: [UnAuthGuard, BrowserGuard],
    children:[
      {path: 'signin', component: SigninComponent },
      {path: 'signup', component: SignupComponent },
      {path: 'reset-password', component: ResetComponent },
    ] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, BrowserGuard],
    children:[
      {path: 'overview', component: OverviewComponent, canActivate: [AuthGuard, BrowserGuard] },
      {path: 'upload', component: UploadComponent, canActivate: [AuthGuard, BrowserGuard] },
      {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard, BrowserGuard] },
      {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard, BrowserGuard] },
      {path: '', redirectTo: 'overview', pathMatch: 'full' }
  ] },
  { path: '**', redirectTo: '', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
