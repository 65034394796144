import { Directive, Output, Input, EventEmitter, HostBinding, HostListener } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';


@Directive({
  selector: '[appDragdrop]'
})
export class DragdropDirective {
  @Output() onFileDropped = new EventEmitter<any>();
  @HostBinding('style.background-color') private backgroundcolor = '#F8F9FB';

  constructor( private _snackBar: MatSnackBar) { }


  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.backgroundcolor = "#f1f5fc"; 
  }
  
  @HostListener('dragleave', ['$event']) onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.backgroundcolor = "#F8F9FB";
  }

  @HostListener('drop', ['$event']) onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.backgroundcolor = "#F8F9FB"; 
    let files = evt.dataTransfer.files;
    if (files.length > 0 && files.length <= 1)  {
      this.onFileDropped.emit(files);
    }
    else if (files.length > 1) {
      this._snackBar.open('请选择单个文件上传', '', {duration: 3000,  panelClass :['snackbar-error']});
    }
  }
}
