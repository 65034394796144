import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-content-home',
  templateUrl: './content-home.component.html',
  styleUrls: ['./content-home.component.scss']
})
export class ContentHomeComponent implements OnInit {
  @ViewChild('counter', {static: false}) private counter: ElementRef<HTMLDivElement>;
  
  count_cx: number = 93;
  count_uni: number = 1;
  count_file: number = 478;
  count_cx_target: number = 572;
  count_uni_target: number = 25;
  count_file_target: number = 932;
  count_duration:number = 3;
  speed_cx:number = (this.count_cx_target - this.count_cx) / this.count_duration
  speed_uni:number = (this.count_uni_target - this.count_uni) / this.count_duration
  speed_file:number = (this.count_file_target - this.count_file) / this.count_duration
  interval:any;
  
  switcher_cmt: boolean = false;
  switcher_counter:boolean = false;
  
  
  constructor() { 
  }
  
  ngOnInit(): void {
  }
  
  
  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(){
    if ((this.counter.nativeElement.getBoundingClientRect().top < window.innerHeight) && (this.switcher_counter==false)){
      this.switcher_counter = true;
      this.update_counter();
    }
  }

  update_counter(){
    this.interval = setInterval(() => {
      this.cal_counter();
      }, 10);
  }


  cal_counter(){
    if (this.count_cx < this.count_cx_target) {
      this.count_cx += this.speed_cx/100;
    }
    if (this.count_uni < this.count_uni_target) {
      this.count_uni += this.speed_uni/100;
    }
    if (this.count_file < this.count_file_target) {
      this.count_file += this.speed_file/100;
    }
    
    if ((this.count_cx >= this.count_cx_target) && (this.count_uni >= this.count_uni_target) && (this.count_file >= this.count_file_target)) {
      clearInterval(this.interval);

    }
  }
}
