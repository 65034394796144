<div class="ctr">
    <div class="ctr-1" id="ctr-1-info" [formGroup]="settingsFormGroup">
        <h1>个人信息</h1>
        <div class="ctr-2">
            <span>性别</span>
            <mat-form-field appearance="none">
                <mat-select disableRipple placeholder="请选择性别" formControlName="gender">
                  <mat-option [value]="1">男生</mat-option>
                  <mat-option [value]="2">女生</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="ctr-2">
            <span>姓 (中文)</span>
            <mat-form-field appearance="none" [style]="style_error_settingspwFG('last_name')">
                <input matInput placeholder="请输入您的姓" formControlName="last_name">
            </mat-form-field>
        </div>
        <div class="ctr-2">
            <span>名 (中文)</span>
            <mat-form-field appearance="none" [style]="style_error_settingspwFG('first_name')">
                <input matInput placeholder="请输入您的名" formControlName="first_name">
            </mat-form-field>
        </div>
        <div class="ctr-2">
            <span>手机号码 (+49为开头)</span>
            <mat-form-field appearance="none" [style]="style_error_settingspwFG('phone_number')">
                <input matInput placeholder="请输入您的电话号码 (+49)" formControlName="phone_number">
            </mat-form-field>
        </div>
        <button mat-raised-button (click)="update_customer_data()">更新信息</button>
    </div>
    <div class="ctr-1" id="ctr-1-password" [formGroup]="restpwFormGroup">
        <h1>密码修改</h1>
        <div class="ctr-2">
            <span>当前密码</span>
            <mat-form-field appearance="none" [style]="style_error_restpwFG('password')">
                <input matInput placeholder="请输入当前密码" type="password" formControlName="password">
            </mat-form-field>
        </div>
        <div class="ctr-2">
            <span>新密码</span>
            <mat-form-field appearance="none" [style]="style_error_restpwFG('password_new')">
                <input matInput placeholder="请输入新密码 (至少6位)" type="password" formControlName="password_new" (change)="check_password_confirm()">
            </mat-form-field>
        </div>
        <div class="ctr-2">
            <span>确认新密码</span>
            <mat-form-field appearance="none" [style]="style_error_restpwFG('password_confirm')">
                <input matInput placeholder="请确认新密码 (至少6位)" type="password" formControlName="password_confirm" (change)="check_password_confirm()">
            </mat-form-field>
        </div>
        <button mat-raised-button (click)="update_password()">更新密码</button>
    </div>
    <div class="ctr-1" id="ctr-1-deactivation">
        <div id="ctr-2-checkbox">
            <mat-checkbox [(ngModel)]="is_accepted" color="warn">
                注销账号请打勾, 并点击下方按钮 (注意: 账号内数据可在三个月内恢复)
            </mat-checkbox>
        </div>
        <button mat-raised-button id="ctr-2-btn" 
                [disabled]="!is_accepted"
                (click)="deactivate_user()" 
                [style.background-color]="!is_accepted ?'#cccccc' : '#f44336'">
            注销账号
        </button>
    </div>
</div>
