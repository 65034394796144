<div class="ctr">
    <div class="ctr-1" id="ctr-1-weather"  [ngStyle]="{backgroundImage:'url(https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/background/weather/' + weather.icon +'.png)'}">
        <div id="ctr-2-info">
            <div id="ctr-3-current">
                <span id="current-number">{{weather.current}}</span>
                <span id="current-symbol">°c</span>
            </div>
            <div id="ctr-3-maxmin">
                <span id="maxmin-max">{{"最高温 " + weather.max}}</span>
                <span id="maxmin-min">{{"最低温 " + weather.min}}</span>
            </div>
          
        </div>
        <div id="ctr-2-icon">
            <img id="ctr-3-icon" [src]="'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/weather/' + weather.icon + '.svg'">
            <span id="ctr-3-desc">{{weather.icon | WeatherDescPipe}}</span>
        </div>
    </div>
    <div class="ctr-1 ctr-fbg" id="ctr-1-system-msg">
        <div class="ctr-2-bg"></div>
        <div class="ctr-2-fg">
            <div id="ctr-3-icon">
                <img [src]="'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/system_msg.svg'">
            </div>
            <div id="ctr-3-title">
                <span>最新资讯</span>
            </div>
        </div>
        <div id="ctr-3-info">欢迎使用REKO用户系统 <br>当前暂无优惠活动<br> 具体详情请咨询客服 </div>
    </div>
    <div class="ctr-1 ctr-fbg" id="ctr-1-system-info">
        <div class="ctr-2-bg">
        </div>
        <div class="ctr-2-fg">
            <div id="ctr-3-icon">
                <img [src]="'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/system_info.svg'">
            </div>
            <div id="ctr-3-title">
                <span>系统信息</span>
            </div>
        </div>
        <div id="ctr-3-info">
            当前系统为测试版: Beta v0.96
        </div>
    </div>


    <div class="ctr-1" id="ctr-1-count">
        <div class="ctr-2-fg">
            <div>
                已添加订单
            </div>
            <div id="ctr-3-icon">
                <img [src]="'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/add.svg'" [routerLink]="['../upload']">
            </div>
            <div id="ctr-3-num">
                {{!dataSource? 0 : dataSource.data.length}}
            </div>
            <div id="ctr-3-img">
                <img [src]="'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/loader.svg'">
            </div>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-latest-order">
        <div id="ctr-2-titel">
            <h1>最新订单</h1>
            <span>{{!dataSource? "" : "订单号: " + (dataSource.data[0].id | slice:0:8) }}</span>
        </div>
        <div id="ctr-2-status">
            <div class="ctr-3-spinner">
                <div  [class]="!downloadable? 'text' : 'text white'">
                    {{!dataSource? "0%" : ((dataSource.data[0].status | OrderStatusPipe: 0) != '100' ? (dataSource.data[0].status | OrderStatusPipe: 0) + "%": "")}}
                    <a *ngIf="downloadable" [href]="dataSource.data[0].file_employee_url" (click)="update_order_status(dataSource.data[0].id)" matTooltip="点击下载">
                        <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/file_download.png"  alt="">                        
                    </a>
                </div>
                <div [class]="!dataSource? 'circle lv1 no_order' : 'circle lv1'">
                    <div [class]="!dataSource? 'circle lv2 no_order' : 'circle lv2'">
                        <div [class]="!dataSource? 'circle lv3 no_order' : 'circle lv3'"></div>
                    </div>
                </div>
            </div>
            <span>{{!dataSource? "无最新订单" : dataSource.data[0].status | OrderStatusPipe: 1}}</span>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-orders">
        <h1>订单列表</h1>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
                <ng-container matColumnDef="id">
                    <th  mat-header-cell *matHeaderCellDef> 订单号 </th>
                    <td mat-cell *matCellDef="let element"> {{element.id | slice:0:8}} </td>
                </ng-container>
                <ng-container matColumnDef="file_customer">
                    <th  mat-header-cell *matHeaderCellDef> 文档名</th>
                    <td mat-cell *matCellDef="let element" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;" [matTooltip]="element.file_customer" matTooltipPosition="above"> {{element.file_customer}} </td>
                </ng-container>
                <ng-container matColumnDef="datetime_joined">
                    <th mat-header-cell *matHeaderCellDef> 创建日期 </th>
                    <td mat-cell *matCellDef="let element"> {{element.datetime_joined | date:"yyyy.MM.dd"}} </td>
                </ng-container>
                <ng-container matColumnDef="catalog">
                <th mat-header-cell *matHeaderCellDef> 订单类型</th>
                <td mat-cell *matCellDef="let element"> {{element.catalog | OrderCatalogPipe}} </td>
                </ng-container>
                <ng-container matColumnDef="urgency">
                <th mat-header-cell *matHeaderCellDef> 订单加急 </th>
                <td mat-cell *matCellDef="let element"> {{element.urgency | OrderUrgencyPipe }} </td>
                </ng-container>
                <ng-container matColumnDef="expense">
                <th mat-header-cell *matHeaderCellDef> 支付金额 </th>
                <td mat-cell *matCellDef="let element"> {{element.amount_gross | number : '1.2-2'}}€ </td>
                </ng-container>
                <ng-container matColumnDef="date_end">
                <th mat-header-cell *matHeaderCellDef> 交付日期 </th>
                <td mat-cell *matCellDef="let element"> {{element.date_end | date:"yyyy.MM.dd"}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> 订单状态 </th>
                <td mat-cell *matCellDef="let element">
                    <mat-progress-bar mode="determinate" 
                    [value]="element.status | OrderStatusPipe: 0"
                    [class]="element.status | OrderStatusPipe: 2"
                    [matTooltip]="element.status | OrderStatusPipe: 1"
                    matTooltipPosition="above"></mat-progress-bar>
                </td>
                </ng-container>
                <ng-container matColumnDef="file_download">
                <th mat-header-cell *matHeaderCellDef> 文档下载 </th>
                <td mat-cell *matCellDef="let element"> 
                    <a [href]="element.file_customer_url">
                    <mat-icon  class="material-icons-outlined" matTooltip="原始文档 (点击下载)" matTooltipPosition="above">article</mat-icon>
                    </a> 
                    <a *ngIf="element.file_employee" [href]="element.file_employee_url">
                    <mat-icon  class="material-icons-outlined" matTooltip="润色文档 (点击下载)" matTooltipPosition="above">assignment_turned_in</mat-icon>
                    </a>
                    <a *ngIf="!element.file_employee">
                    <mat-icon  class="material-icons-outlined mat-icon-disable" matTooltip="订单润色完成后, 文档即可下载" matTooltipPosition="above">loop</mat-icon>
                    </a>
                </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
            </table>
            <span *ngIf="!dataSource">订单列表当前为空</span>
        </div>
        <mat-paginator [hidePageSize]="true" [pageSize]="4" showFirstLastButtons ></mat-paginator>
    </div>
</div>