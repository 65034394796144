import { Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material/snack-bar';

import { AuthService } from '../auth.service'
import { DashboardService } from '../../dashboard/dashboard.service'

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  constructor( private _router: Router, private _authService: AuthService, private _dbService: DashboardService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void { }

  signin(): void {
    this.signinFormGroup.markAllAsTouched();

    if (this.signinFormGroup.valid) {
      let data_request:any = {
        'username': this.signinFormGroup.get('email').value.toLowerCase(),
        'password': this.signinFormGroup.get('password').value,
      }
      
      this._authService.get_token(data_request)
      .subscribe(
        async result  => {
          localStorage.setItem("access", result['access']);
          localStorage.setItem("refresh", result['refresh']);
          localStorage.setItem("email", this.signinFormGroup.get('email').value);
          // update user signin info
          this._dbService.ipclient_log({'catalog': 2}).subscribe();
          this._router.navigate(['../dashboard/overview',]);
        },
        error => {
          this._dbService.user_check_account(data_request).subscribe( data => {
            this._snackBar.open(data['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
          });
        }
      )
    }
  }

}
