import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrowserGuard implements CanActivate {
  constructor(private _router: Router){}

  canActivate () {
    // this._router.navigate(['/dashboard']);
    if (navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1) {
      this._router.navigate(['/no-support']);
      return false
    }
    else
      return true;
  }
}
