<div id="footer">
    <div class="ctr-footer" id="ctr-footer-1">
        <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/copyright_2021.svg" alt="">
        <div class="icon_payment">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_paypal.svg" alt="">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_giropay.svg" alt="">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_sepa.svg" alt="">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_sofort.svg" alt="">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_visa.svg" alt="">
        </div>
    </div>
    <div class="ctr-footer" id="ctr-footer-2">
        <div id="ctr-legal">
            <span>法律信息</span>
            <span><a href="/impressum">Impressum</a></span>
            <span><a href="/datenschutz">Datenschutz</a></span>
        </div>

        <div id="ctr-q&a">
            <span>常见问题</span>
            <span><a href="/q&a#revision">文本校对</a></span>
            <span><a href="/q&a#upload&payment">上传付款</a></span>
            <span><a href="/q&a#aftersale">售后维权</a></span>
            <span><a href="/q&a#privacy">隐私保护</a></span>
        </div>
    </div>
    <div class="ctr-footer" id="ctr-footer-3">
        <span class="title">联系我们</span>
        <div class="ctr-footer-3-contact">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_wechat.svg" alt="">
            <span>DE_REKO2020</span>
        </div>
        <div class="ctr-footer-3-contact">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_email.svg" alt="">
            <span><a href="mailto:service@ireko.de">service@ireko.de</a></span>
        </div>
    </div>
</div>