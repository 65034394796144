import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-example',
  templateUrl: './content-example.component.html',
  styleUrls: ['./content-example.component.scss']
})
export class ContentExampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
