import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service'
import { Observable, of  } from 'rxjs';
import {  map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})


export class UnAuthGuard implements CanActivate {
  constructor(private _router: Router, private _authService: AuthService ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    if (!!localStorage.getItem("refresh")) {
      return this._authService.jwt_verify(localStorage.getItem("refresh")).pipe(
        map(
          result => {
            this._router.navigate(['/dashboard']);
            return false
        }
      ),
        catchError(
          err => {
            localStorage.clear();
            return of(true)
        }
      )
    )
  }
  else {
      localStorage.clear();
      return of(true)
    }
  }
}
