import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'WeatherDescPipe'
})
export class WeatherDescPipe implements PipeTransform {
  transform(icon: string): string {
      if(icon.indexOf('01') >= 0) {
        return "晴";
      }
      else if(icon.indexOf('02') >= 0){
        return "多云";
      }
      else if(icon.indexOf('03') >= 0){
        return "阴";
      }
      else if(icon.indexOf('04') >= 0){
        return "多云";
      }
      else if(icon.indexOf('09') >= 0){
        return "小雨";
      }
      else if(icon.indexOf('10') >= 0){
        return "雨";
      }
      else if(icon.indexOf('11') >= 0){
        return "雷雨";
      }
      else if(icon.indexOf('13') >= 0){
        return "雪";
      }
      else if(icon.indexOf('50') >= 0){
        return "雾";
      }
  }
}

@Pipe({
    name: 'OrderUrgencyPipe'
})
export class OrderUrgencyPipe implements PipeTransform {
    transform(urgency: number): string {
        if(urgency == 1){
          return "标准";
        }else if(urgency == 2){
          return "加急";
        }else if(urgency == 3){
          return "特快";
        }
    }
}

@Pipe({
    name: 'OrderCatalogPipe'
})
export class OrderCatalogPipe implements PipeTransform {
    transform(catalog: number): string {
        if(catalog == 1){
          return "学术论文";
        }
        else if(catalog == 2){
          return "家庭作业";
        }
        else if(catalog == 3){
          return "实习报告";
        }
        else if(catalog == 4){
          return "个人简历";
        }
        else if(catalog == 5){
          return "动机信";
        }
        else if(catalog == 6){
          return "演讲稿";
        }
        else if(catalog == 7){
          return "其他类型";
        }
    }
}

@Pipe({
    name: 'OrderStatusPipe'
})
export class OrderStatusPipe implements PipeTransform {
    transform(status: number, type: number): string {
        if(status == null){
            return ['0', '订单出错, 请立即联系工作人员'][type]
        }
        else if(status == 0){
          //canceled
          return  ['0', '订单已取消', 'mat-progress-bar-orange'][type]
      }
        else if(status == 1){
            //payed
            return  ['10', '付款成功, 工作人员正在确认订单', 'mat-progress-bar-orange'][type]
        }
        else if(status == 2){
            //confirmed
            return ['30', '订单确认成功, 文档润色中...', 'mat-progress-bar-orange'][type]
        }
        else if(status == 3){
            //started
            return ['50', '文档润色中...', 'mat-progress-bar-orange'][type]
        }
        else if(status == 4){
          //finished
          return ['70', '文档润色中...', 'mat-progress-bar-blue'][type]
      }
        else if(status == 5){
            //reviewed
            return ['90', '文档润色完成, 审核与上传中...', 'mat-progress-bar-blue'][type]
        }
        else if([6, 7].includes(status)){
            //uploaded + download
            return ['100', '润色文档已上传', 'mat-progress-bar-green'][type]
        }
    }
}