<div class="ctr">
    <h1>订单管理</h1>
    <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
            <ng-container matColumnDef="id">
              <th  mat-header-cell *matHeaderCellDef> 订单编号 </th>
              <td mat-cell *matCellDef="let element"> {{element.id | slice:0:8}} </td>
            </ng-container>
            <ng-container matColumnDef="file_customer">
              <th  mat-header-cell *matHeaderCellDef> 文档名</th>
              <td mat-cell *matCellDef="let element" style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;"> {{element.file_customer}} </td>
          </ng-container>
            <ng-container matColumnDef="datetime_joined">
              <th mat-header-cell *matHeaderCellDef> 创建日期 </th>
              <td mat-cell *matCellDef="let element"> {{element.datetime_joined | date:"yyyy.MM.dd"}} </td>
            </ng-container>
            <ng-container matColumnDef="catalog">
              <th mat-header-cell *matHeaderCellDef> 订单类型</th>
              <td mat-cell *matCellDef="let element"> {{element.catalog | OrderCatalogPipe}} </td>
            </ng-container>
            <ng-container matColumnDef="urgency">
              <th mat-header-cell *matHeaderCellDef> 订单加急 </th>
              <td mat-cell *matCellDef="let element"> {{element.urgency | OrderUrgencyPipe }} </td>
            </ng-container>
            <ng-container matColumnDef="expense">
              <th mat-header-cell *matHeaderCellDef> 支付金额 </th>
              <td mat-cell *matCellDef="let element"> {{element.amount_gross | number : '1.2-2'}}€ </td>
            </ng-container>
            <ng-container matColumnDef="date_end">
              <th mat-header-cell *matHeaderCellDef> 交付日期 </th>
              <td mat-cell *matCellDef="let element"> {{element.date_end | date:"yyyy.MM.dd"}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> 订单状态 </th>
              <td mat-cell *matCellDef="let element">
                <mat-progress-bar mode="determinate" 
                  [value]="element.status | OrderStatusPipe: 0"
                  [class]="element.status | OrderStatusPipe: 2"
                  [matTooltip]="element.status | OrderStatusPipe: 1"
                  matTooltipPosition="above"></mat-progress-bar>
              </td>
            </ng-container>
            <ng-container matColumnDef="file_download">
              <th mat-header-cell *matHeaderCellDef> 文档下载 </th>
              <td mat-cell *matCellDef="let element"> 
                <a [href]="element.file_customer_url">
                  <mat-icon  class="material-icons-outlined" matTooltip="原始文档 (点击下载)">article</mat-icon>
                </a> 
                <a *ngIf="element.file_employee" [href]="element.file_employee_url" (click)="update_order_status(element.id)">
                  <mat-icon  class="material-icons-outlined" matTooltip="润色文档 (点击下载)" matTooltipPosition="above">assignment_turned_in</mat-icon>
                </a>
                <a *ngIf="!element.file_employee">
                  <mat-icon  class="material-icons-outlined mat-icon-disable" matTooltip="订单润色完成后, 文档即可下载" matTooltipPosition="above">loop</mat-icon>
                </a>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
          </table>
          <span *ngIf="!dataSource">订单列表当前为空</span>
    </div>
    <mat-paginator [hidePageSize]="true" [pageSize]="4" showFirstLastButtons></mat-paginator>
  </div>