import { Component, ViewChild, OnInit } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Weather, Order } from '../dashboard.interface'
import { DashboardService } from '../dashboard.service'

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  weather: Weather = {} as Weather;
  downloadable: boolean;
  dataSource: MatTableDataSource<Order>;
  displayedColumns: string[] = ['id','file_customer', 'datetime_joined', 'catalog',  'urgency', 'status', 'date_end', 'expense'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _dbservice: DashboardService) {}

  
  ngOnInit(){
    this.init_weather();
    this.init_orders(JSON.parse(localStorage.getItem("orders")));
    this.check_downloadable(JSON.parse(localStorage.getItem("orders")));
  }

  ngAfterViewInit(): void{ 
    if (this.dataSource){
      this.dataSource.paginator = this.paginator;
    }
  }

  init_weather(): void {
    const weather = JSON.parse(localStorage.getItem("weather"));
    this.weather.current = String(Math.round(weather['main']['temp']));
    this.weather.max = String(String(Math.round(weather['main']['temp_min'])));
    this.weather.min = String(String(Math.round(weather['main']['temp_max'])));
    this.weather.icon = String(weather['weather'][0]['icon']);
  }

  init_orders (orders: MatTableDataSource<Order>): void {
    if (orders){
      this.dataSource = new MatTableDataSource(orders['orders']);
    }
  }

  check_downloadable (orders: MatTableDataSource<Order>): void {
    if (orders){
      if(orders['orders'][0].file_employee) {
        this.downloadable = true;
      }
    }
  }

  update_order_status (id_order: string): void {
    this._dbservice.order_update_status_download(id_order).subscribe();
  }
}
