<div class="ctr">
    <div class="ctr-1" id="ctr-1-upload">
        <div class="ctr-2" id="ctr-2-upload-area" appDragdrop (onFileDropped)="onDocDropped($event)" >
            <img *ngIf="!doc_uploaded" src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/file_group.svg">
            <img id="ctr-3-filetype" *ngIf="doc_uploaded" [src]="doc_extension == 'docx'? 'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/file_docx.svg' : 'https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/icon/file_pptx.svg'">
            <div id="ctr-3-uploadinfo">
                <mat-progress-bar mode="indeterminate" *ngIf="doc_uploading"></mat-progress-bar>
                <span *ngIf="doc_uploaded">{{preorder.file_customer_name}}</span>
            </div>
            <button (click)="uploader.click()">{{!doc_uploaded ? '文档上传' : '重新上传'}}</button>
            <input hidden type="file" #uploader (change)="onDocChanged($event)" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation">
            <span>或者将文档拖到此处</span>
        </div>
        <div id="ctr-2-info">
            <mat-icon  class="material-icons-outlined mat-icon-disable"  [inline]="true">lock</mat-icon>
            <span>安全数据保护</span>
        </div>
    </div>
    <div class="ctr-1" id="ctr-1-submit">
        <h1>订单信息</h1>
        <span *ngIf='!doc_uploaded'>文档信息 (上传后自动显示)</span>
        <span *ngIf='doc_uploaded' class="ctr-2-info">
            上传文档总计共 {{preorder.count_words}} 字
        </span>
        <mat-form-field appearance="none">
            <mat-select class="ctr-2-info" disableRipple placeholder="文档类型 (上传后请选择)" [formControl]="catalogFormControl" (selectionChange)="onCatalogChanged($event)">
                <mat-option class="ctr-2-info" *ngFor="let cat of catalog" [value]="cat.value" [disabled]="doc_extension == 'pptx'? cat.disabled_pptx : false"> 
                    {{cat.viewValue}} 
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-tab-group dynamicHeight  [class]="class_inkbar" (selectedTabChange)="onUrgencyChanged($event)" [selectedIndex]="+preorder.urgency - 1">
            <mat-tab label="标准" [disabled]="doc_availability['1']['disabled']">
                <div class="tab-container-overlay" *ngIf="!doc_uploaded">
                    <span>文档上传后，自动适配可选加急选项</span>
                </div>
                <div class="tab-container">
                    <div class="tab-container-date_end_t">
                        <span>交付日期</span>
                    </div>
                    <div class="tab-container-date_end">
                        <span>{{doc_availability['1']['date_end']}}</span>
                    </div>
                    <div class="tab-container-duration">
                        <div class="icon-span icon-span-bigger"><mat-icon  class="material-icons-outlined">history_toggle_off</mat-icon><span>{{doc_availability['1']['duration']}} 天润色时间</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>精准的文本润色</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>优质的售后服务</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">clear</mat-icon><span>较长的润色时间</span></div>
                    </div>
                    <div class="tab-container-expense">
                        <span>合计金额: </span>
                        <span class="number">{{doc_availability['1']['amount_customer'] | number : '1.2-2'}}€</span>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="加急" [disabled]="doc_availability['2']['disabled']">
                <div class="tab-container">
                    <div class="tab-container-date_end_t">
                        <span>交付日期</span>
                    </div>
                    <div class="tab-container-date_end">
                        <span>{{doc_availability['2']['date_end']}}</span>
                    </div>
                    <div class="tab-container-duration">
                        <div class="icon-span icon-span-bigger"><mat-icon  class="material-icons-outlined">history_toggle_off</mat-icon><span>{{doc_availability['2']['duration']}} 天润色时间</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>精准的文本润色</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>优质的售后服务</span></div>
                        <div class="icon-span"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>较短的润色时间</span></div>
                    </div>
                    <div class="tab-container-expense">
                        <span>合计金额: </span>
                        <span class="number">{{doc_availability['2']['amount_customer'] | number : '1.2-2'}}€</span>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="特快" [disabled]="doc_availability['3']['disabled']">
                <div class="tab-container">
                    <div class="tab-container-date_end_t">
                        <span>交付日期</span>
                    </div>
                    <div class="tab-container-date_end">
                        <span>{{doc_availability['3']['date_end']}}</span>
                    </div>
                    <div class="tab-container-duration">
                        <div class="icon-span icon-span-bigger"><mat-icon  class="material-icons-outlined">history_toggle_off</mat-icon><span>{{doc_availability['3']['duration']}} 天润色时间</span></div>
                        <div class="icon-span icon-color"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>精准的文本润色</span></div>
                        <div class="icon-span icon-color"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>优质的售后服务</span></div>
                        <div class="icon-span icon-color"><mat-icon  class="material-icons-outlined" [style.color]="class_icon">check</mat-icon><span>极速的润色时间</span></div>
                    </div>
                    <div class="tab-container-expense">
                        <span>合计金额: </span>
                        <span class="number">{{doc_availability['3']['amount_customer'] | number : '1.2-2'}}€</span>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <button [class]="class_button" mat-raised-button [disabled]="!doc_uploaded || catalogFormControl.invalid || this.is_paying" (click)="startPayment()">验证订单</button>
    </div>

</div>