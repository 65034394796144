import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { ConfigModule } from '../app-config'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  config = new ConfigModule();
  url_user = this.config.be_ip + 'user/'
  url_customer = this.config.be_ip + 'customer/'
  url_ipclient = this.config.be_ip + 'ipclient/'
  url_preorder = this.config.be_ip + 'preorder/'
  url_order = this.config.be_ip + 'order/'
  url_transaction_customer= this.config.be_ip + 'transaction_customer/'
  url_coupon = this.config.be_ip + 'coupon/'
  url_systembroadcast = this.config.be_ip + 'systembroadcast/'


  constructor( private _http: HttpClient ) { }

  // API IpClient
  ipclient_log(data) {
    return this._http.post(this.url_ipclient + 'log/', data)
  }
  

  // API User
  user_check_account(data){
    return  this._http.post(this.url_user + 'check_account/', data)
  }

  async get_user_id() {
    return await this._http.post(this.url_user + 'get_id/', null).toPromise().then()
  }
  
  update_password(user_id: string, data): Observable<any> {
    return this._http.post(this.url_user + user_id + '/update_password/', data)
  }
  
  deactivate_user(user_id: string): Observable<any> {
    return this._http.post(this.url_user + user_id + '/deactivate/', null)
  }
  
  // API Customer
  async get_customer_id() {
    return await this._http.post(this.url_customer + 'get_id/', null).toPromise().then()
  }

  async get_orders(customer_id: string) {
    return await this._http.post(this.url_customer + customer_id + '/get_orders/',  null).toPromise().then()
  }

  async get_customer_info(customer_id: string) {
    return await this._http.post(this.url_customer + customer_id + '/get_info/', null).toPromise().then()
  }
  
  update_customer_info(customer_id: string, data) {
    return this._http.post(this.url_customer + customer_id + '/update_info/', data)
  }

  //API PreOrder
  create_preorder (data) {
    return this._http.post(this.url_preorder, data)
  }
  
  check_preorder_availability (preorder_id: string) {
    return this._http.post(this.url_preorder + preorder_id + '/check_availability/', null)
  }
  
  update_preorder (preorder_id: string, data) {
    return this._http.post(this.url_preorder + preorder_id + '/update_preorder/', data)
  }
  
  deactivate_preorder (preorder_id: string) {
    return this._http.post(this.url_preorder + preorder_id + '/deactivate_preorder/', null)
  }

  //API Order
  async create_order (data) {
    return await this._http.post(this.url_order, data).toPromise().then()
  }

  order_send_confirmation_payment_received(order_id: string) {
    return this._http.post(this.url_order + order_id + '/send_confirmation_payment_received/', null)
  }

  order_update_transaction_cx (order_id: string) {
    return this._http.post(this.url_order + order_id + '/update_transaction_cx/', null)
  }
  
  order_update_status_download (order_id: string) {
    return this._http.post(this.url_order + order_id + '/update_status_download/', null)
  }

  //API transaction_customer
  async create_transaction_customer (data) {
    return await this._http.post(this.url_transaction_customer, data).toPromise().then()
  }

  // API Coupon
  check_coupon_availability(data){
    return this._http.post(this.url_coupon + 'check_availability/', data)
  }

  // API SystemBroadcast
  async systembroadcast_get_weather(){
    return await this._http.post(this.url_systembroadcast + 'get_weather/', null).toPromise().then()
  }
}
