<img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/q&a/bg_1.png?time=${Date.now()}" id="bg-title">

<div id='ctr-1'>
    <span id='title-1'>常见问题</span>
    <span id='title-2'>FAQ</span>
    <span id='title-3'>最后更新时间: {{date.getFullYear() + " / " + (date.getMonth()+1) + " / " + (date.getDate()-7)}}</span>
</div>

<div id='ctr-2'>
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <div class="ctr-faq">
            <div class="ctr-faq-area">
                <span class="material-icons">content_paste_search</span>
                <span id="revision" class="faq-title">文本校对</span>
            </div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>什么是论文校对？</mat-panel-title></mat-expansion-panel-header>
                    <p>校对(Korrekturlesen)是指查找论文(或其他文档)中出现的下列语言文字问题，并对其进行修正和改进：</p>
                    <ul>
                        <li>拼写错误</li>
                        <li>语法错误</li>
                        <li>用词不准确</li>
                        <li>引用格式错误</li>
                        <li>标点符号使用不当</li>
                        <li>风格不符合母语者的表达习惯，词不达意等</li>
                      </ul>  
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>校对论文合法吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>对学士，硕士或其他学位论文等进行校对是符合德国法律的，前提是不对论文的内容进行任何修改。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>REKO提供代写论文服务吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        REKO不提供且不支持任何形式的代写服务! 论文代写、抄袭，属于严重的违法行为，
                        请勿向客服发送邮件咨询（咨询代写类邮件概不回复，微信客服也建议不要加哦，可能会被客服严厉谴责！）
                        请勿挑战德国法律，以及我们底线！
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title> 可以对我的文本内容进行专业性的修改吗 (Lektorat)？</mat-panel-title></mat-expansion-panel-header>
                    <p>我们目前的服务只限于Korrekturlesen，您文本中的语言文字由我们把关，所有语言文字类的问题或错误将被修正和改进。
                        <b>REKO当前并不提供Lektorrat的服务</b>，校对人员不会对您文章中的逻辑以及专业内容的准确性等涉及文本内容方面的问题进行润色。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>校对者了解我的专业领域吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        我们的校对人员是各学科领域学者。您可以在上传过程中选择文档类型，
                        并且备注您的专业领域，REKO将匹配熟悉您的专业领域校对人员进行修改。
                        这意味着他（她）将从专业的角度进行语言文字的校对润色，但对内容不会进行任何修改。
                        专业领域涵盖：
                    </p>
                    <ul>
                        <li>工商管理</li>
                        <li>经济学</li>
                        <li>IT与工程</li>
                        <li>自然科学</li>
                        <li>人文学科等</li>
                    </ul> 
                    <p>
                        所有校对人员都通过了REKO资质审核，并接受了相应的培训。REKO还会定期检查他们的工作，以确保始终为您提供最优质的服务。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>网站的校对人员都是母语者吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>REKO的每一名校对人员都是有专业背景的母语者，且绝对不会出现非母语者进行校对润色的情况。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以使用REKO校对哪些类型的文本？</mat-panel-title></mat-expansion-panel-header>
                    <ul>
                        <li>专业文章，学期论文</li>
                        <li>学位论文 (学士，硕士，博士)</li>
                        <li>实习报告</li>
                        <li>个人简历</li>
                        <li>求职/学校申请书，动机信</li>
                        <li>项目工作报告</li>
                        <li>求职/演讲稿，演示ppt</li>
                        <li>其他文本类型的校对请通过联系客服来进行确认</li>
                    </ul> 
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我的论文润色后一定可以拿高分吗?</mat-panel-title></mat-expansion-panel-header>
                    <p>教授是否认可一篇论文，很大程度取决于论文的内容与逻辑，文章通畅且无语法错误只是最基础的部分。
                        由于REKO只对文本中的语言文字把关，并不提供内容方面的修改，请您务必在文本润色前与导师进行内容方面的确认。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以直接和校对人员联系吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        您不能自己联系校对人员，请通过REKO客服进行沟通。<br><br>
                        <b>关于服务的问题:</b><br>
                        如果您对我们的服务有任何疑问，可以随时通过微信客服或电子邮件(<a href="mailto:service@ireko.de">service@ireko.de</a>)与我们联系。<br><br>
                        <b>订单备注:</b><br>
                        在确认订单过程中，您可以向校对人员提出任何需求。将您的特殊要求填写在备注栏即可，系统会将其一并发给校人员。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>REKO是否也为我的LaTeX文档进行校对？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        是的，我们也为您的 LaTeX 文档提供编辑和校对服务。由于我们的校对人员使用Microsoft Word中的“修订审阅”功能，您必须将文档从LaTeX文档(.tex)转换为Word文档(.docx)。
                        将LaTeX文档转换成Word文档并不困难，但可能改变文档的布局。因此，我们建议您检查转换后Word文档的格式布局，确认无误后再进行创建订单。
                        文档润色完成后，将Word文档中的内容直接复制粘贴到LaTeX文档中对应的部分即可。<br><br>
                        <b>如何将LaTex文档转换格式Word文档:</b>
                    </p>
                    <ul>
                        <li>将LaTeX文档编译为PDF文档</li>
                        <li>
                            将PDF文档转换成Word文档(docx)<br>
                            方法一: 使用 Microsoft Word打开PDF文档，Word会自动将您的PDF转换为Word文档，最后另存为Word文档(docx)<br>
                            方法二: 使用免费的网页文档转换器: <a href="https://www.adobe.com/acrobat/online/pdf-to-word.html" target="_blank">https://www.adobe.com/acrobat/online/pdf-to-word.html</a> 
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以直接使用Google Übersetzer将中文论文翻译成德语吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        切忌将大篇幅或长句式的中文直接用翻译软件翻译成德语。根据校对人员的反馈，直接使用翻译软件翻译的文本存在严重的问题，大部分语句让人完全摸不清头绪，更谈不上校对或修改。
                        本着对您的文本认真负责的态度，我们会在您付款后审核您的订单是否直接由翻译软件翻译完成，以此来确认我们的校对人员能否为您提供相应的服务。我们的建议: 
                        您可以在写论文的过程中适度使用Google等翻译软件，但基本的句式和语法一定要由自己来把关。您可能会担心自己的词汇或语法比较初级，但比起"高级的"用法，更重要的是，您的导师教授或者校对人员可以读懂您文章的内容。
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="ctr-faq">
            <div class="ctr-faq-area">
                <span class="material-icons">drive_folder_upload</span>
                <span id="upload&payment" class="faq-title">上传付款</span>
            </div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>修改校对的时间一般需要多久？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        由于多方面的因素(包括但不限于当前校对人员的工作时长，当前系统订单总量，您上传文档的字数等)，客服是无法提供校对时长的。
                        系统会在您上传文档后自动显示准确校对时长，REKO建议您，在文本完成或者即将完成的时候，
                        登录个人主页并上传文档，以获得最准确的修改润色时间，并根据您的需求来选择不同的时间加急选项。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以更改原始上传文档吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>不可以，您不能更改已上传的原始文档，请务必在付款前仔细检查确认。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以上传哪些文件格式？</mat-panel-title></mat-expansion-panel-header>
                    <p>支持上传的文件格式：<b>docx</b>，<b>pptx</b> <br>pdf格式无法编辑，因此不支持上传。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>订单的价格是如何计算的？</mat-panel-title></mat-expansion-panel-header>
                    <P>起始价格为<b>14.95€</b>(文本字数<b>600</b>字以内)，多余部分按<b>4.99€/页</b>(300字)累计，适用于所有文本类型。</P>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>有哪些付款方式？</mat-panel-title></mat-expansion-panel-header>
                    <P>REKO支持以下付款方式:</P>
                    <ul>
                        <li>贝宝（PayPal）</li>
                        <li>信用卡（Visa，MasterCard）</li>
                        <li>即时转账 (Softüberwesiung, Klarna)</li>
                        <li>Giropay</li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我可以在校对结束后付款或者分期付款吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>不可以，很遗憾您无法进行逾期或分期付款。在校对人员可以开始校对您的文本之前，我们需要确认付款成功，并生成订单。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我在哪里能找到优惠码？</mat-panel-title></mat-expansion-panel-header>
                    <p>注册后进入个人页面，在主页的最新资讯中查看即可（折扣活动不定期更新）。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我下单后怎么查看订单进度呢？</mat-panel-title></mat-expansion-panel-header>
                    <p>下单后，在用户的个人主页会实时显示当前的订单进度。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>校对人员会遵守校对时间吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>是的，您的校对人员将在截止日期当天的19:00前完成校对。您可以随时在您的个人主页中查看截止日期和订单状态，若您的文本完成校对，您将立即收到电子邮件通知。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我最多可以上传多大的文件？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        REKO支持最大文件为: <b>20MB</b> <br>
                        遗憾的是，校对人员无法修改较大的文档。原因是我们的后台安全服务器无法快速的存储过大的文件。此外，文档的过大也会使得校对人员难以打开和编辑。<br><br>
                        如果您的文档大于指定的最大值，这可能是由于文档中的图片较大，有多种方法可以压缩文档:<br>
                    </p>
                    <ul>
                        <li>
                            <b>压缩图片:</b> Word提供了压缩图片的功能，通过该功能可以缩小图形的大小而不会明显降低质量。在Word2016中，您只需单击图形，选择“格式”选项卡，然后单击“压缩图片”。
                        </li>
                        <li>
                            <b>删除图片:</b> 但如果压缩图片后文档仍然太大，最好删除较大的图片，直到低于最大容量。
                        </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>我的文档会在周末和公共假期照常进行校对吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>是的，我们的校对人员会在周末和公共假日(圣诞假期除外)对您的文档进行校对，并在系统给定的截止日期上传校对完毕的文档。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>在处理订单时，我还可以在订单中添加其他的文件吗？</mat-panel-title></mat-expansion-panel-header>
                    <p>下订单后，文档将不能在进行更改。如果有特殊情况，您可以发送电子邮件至<a href="mailto:service@ireko.de">service@ireko.de</a>或者联系客服微信，注明情况，工作人员会依照实际情况进行处理及反馈。</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="ctr-faq">
            <div class="ctr-faq-area">
                <span class="material-icons">support_agent</span>
                <span id="aftersale" class="faq-title">售后维权</span>
            </div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>如何取消我的订单？</mat-panel-title></mat-expansion-panel-header>
                    <p>如果您要取消订单，请在付款后尽快与我们联系，订单确认后校对工作将立即开始，根据实际情况，您需支付校对工作已产生的费用。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>怎么找到修改校对后的文档？</mat-panel-title></mat-expansion-panel-header>
                    <p>修改校对结束后，REKO会通过邮件来通知您订单完成，届时您可以登录个人主页，下载修改校对版本的文档。</p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>有关于修订文本的问题，如何联系校对人员？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        如果您对校对文档有任何疑问，发送电子邮件至客服<a href="mailto:service@ireko.de">service@ireko.de</a>，
                        在电子邮件中列出您所有的问题，我们会就您的问题与校对人员进行沟通，并尽快答复。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>订单完成后，不满意应该如何投诉呢？</mat-panel-title></mat-expansion-panel-header>
                    <p>如果您对修订文档不满意，请在追诉时效内<b>(订单交付日期的后七天内)</b>与我们联系。我们会在24小时回复您，并共同寻找合适的解决方案。</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class="ctr-faq">
            <div class="ctr-faq-area">
                <span class="material-icons">security</span>
                <span id="privacy" class="faq-title">隐私保护</span>
            </div>
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>如何删除我的账户？</mat-panel-title></mat-expansion-panel-header>
                    <p>
                        登录网站，转到“ 个人信息”，然后单击“删除帐户”。
                        注意：如果删除您的帐户，您的所有数据将全部清除，您的投诉无法再被受理。
                        包括您的所有文件和订单数据将被删除，这是不可撤销的。
                        REKO团队也不再有权访问您的文档。
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header><mat-panel-title>REKO如何处理机密文件？</mat-panel-title></mat-expansion-panel-header>
                    <p>所有客服资料及文档均严格保密，我们的工作人员将遵守以下几点条约来确保数据安全：</p>
                    <ul>
                        <li>严禁交易客户个人数据</li>
                        <li>严禁挪用客户文档发行和出版</li>
                        <li>创造安全的互联网连接环境</li>
                        <li>修订后校对员及时删除文件</li>
                    </ul>                
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div id="ctr-13">
        <div class="ctr-wrapper">
            <span id="title-1">做最完美的事</span>
            <span id="title-2">想要尽快获得更多资讯，开启你的文本润色之旅，
                以及完整的用户体验，点击快速注册，数据安全，绿色无广告
            </span>
            <div [routerLink]="['/signup']">
                <span>快速注册</span>
            </div>
        </div>
    </div>
</div>