<div id="navbar">
    <img id="logo_pc" src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/logo/logo_white.svg" [routerLink]="['..']">
    <img id="logo_mobil" src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/logo/logo_white_mobil.svg" [routerLink]="['..']">
    <div id="tag-nav">
        <a class="nav-link" href="">主页</a>
        <a class="nav-link" href="#solution">服务介绍</a>
        <a class="nav-link" href="/example">参考示例</a>
        <a class="nav-link" href="/q&a">常见问题</a>
    </div>
    <div id="btn-nav"  [routerLink]="['/signin']">
        <span>SIGN IN</span>
    </div>
</div>

