import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AuthService } from '../auth.service'

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  is_vaild: boolean = false;
  verification_id: string; 

  resetFormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(50)]),
    password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]),
    password_confirm: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(30)])
  })

  codeFormControl = new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]);

  constructor( private _router: Router, private _authService: AuthService, private _snackBar: MatSnackBar ) { }

  ngOnInit(): void {
  }

  check_password_confirm(): void {
    if (this.resetFormGroup.get('password').value != this.resetFormGroup.get('password_confirm').value) {
      this.resetFormGroup.get('password_confirm').setErrors({'incorrect': true});
    }
    else if (this.resetFormGroup.get('password').valid && (this.resetFormGroup.get('password').value == this.resetFormGroup.get('password_confirm').value)){
      this.resetFormGroup.get('password_confirm').setErrors(null);
    }
  }

  verify_email():void {
    this.resetFormGroup.markAllAsTouched();
    if (this.resetFormGroup.valid) {
      let data_request:any = {
        'catalog': 2,
        'username': this.resetFormGroup.get('email').value,
        'password': this.resetFormGroup.get('password').value,
      }

      this._authService.verify_email(data_request)
        .subscribe(
          result => {
            this.verification_id = result['id']
            this._snackBar.open("激活码已发送至邮箱", '', {duration: 3000,  panelClass :['snackbar-success']});
            this.is_vaild = true;
        },
          error => {
            this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
        }
      )
    }
  }

  code_activate():void {
    this.codeFormControl.markAllAsTouched();
    if (this.codeFormControl.valid){
      let data_request:any = {
        'code': this.codeFormControl.value,
      }

      this._authService.activate_code(this.verification_id, data_request)
        .subscribe(
          result => {
            this._router.navigate(['../signin',]);
            this._snackBar.open(result['detail'], '', {duration: 3000,  panelClass :['snackbar-success']});
          },
          error => {
            this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
          }
      )
    }
  }
}
