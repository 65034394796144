<video  #videoPlayer
        muted
        autoplay="autoplay"
        loop="loop"
        x5-playsinline="true"
        playsinline="true"
        webkit-playsinline="true"
        x-webkit-airplay="allow"
        preload="auto"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        oncanplay="this.play()" 
        onloadedmetadata="this.muted = true">
        <source src="https://s3.eu-central-1.amazonaws.com/reko.assets/vid/intro.mp4" type="video/mp4">
</video>
<div id="ctr-video"></div>

<div id="ctr-1">
    <div id="ctr-slogan">
        <span id="slogan_pc">让德语更有温度</span>
        <span id="slogan_mobile" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1000">让德语<br>更有温度</span>
        <div id="ctr-underline"></div>
        <div id="ctr-underblock" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1000"  data-aos-delay="1000" data-aos-offset="-2000"></div>
    </div>
</div>

<div id="solution">
    <div class="ctr-bg"></div>
    <div class="ctr-fg"  data-aos="fade-up" data-aos-duration="2000">
        <div id="ctr-block"></div>
        <div id="ctr-sideline"></div>
        <span>WER SIND WIR?</span>
        <p>
            在德国的学习生活中，常因为德语表达不及母语者准确，而遇到各种各样的问题。
            有时彻夜不眠、绞尽脑汁写出来的论文、报告无法得到导师、教授的认可，
            充满期待递交的简历、求职信却石沉大海。通过小广告找到的“机构”，又担心质量和时间没有保障，
            甚至还有可能“人”财两空。这些问题让REKO来解决，我们的团队秉承着“专业、安全、有温度”服务理念，为您的文本注入母语者的灵魂。
            让您在德国的学习生活，多一份温暖，多一份轻松，多一份机会！
        </p>
    </div>
</div>

<div id="ctr-3">
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <div class="ctr-intro-emp" id="ctr-top" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/avatar_1.jpg" class="avatar ctr-content">
            <div class="ctr-text">
                <p>
                    Wir wollen, dass du Erfolg im Studium, bei der Bewerbung oder im Job hast — deswegen helfen wir dir, 
                    sprachlich alles richtig zu machen. Dafür korrigieren wir dein Dokument auf korrekte Rechtschreibung und Interpunktion, 
                    grammatische Richtigkeit, fehlerfreie Syntax und sprachliche Angemessenheit, damit dein Text auch sprachlich gut ankommt!
                </p>
                <span>
                    Katharina, Co-Founder
                </span>
            </div>
        </div>
        <div class="ctr-intro-emp" id="ctr-bot" data-aos="fade-up" data-aos-duration="2000">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/avatar_2.jpg" class="avatar">
            <div class="ctr-text">
                <p>
                    Gutes Deutsch ist unsere Passion, daher kannst du dich auf uns verlassen, wenn es darum geht, 
                    den richtigen Ton zu treffen. Wir arbeiten dank unserer langjährigen Erfahrung schnell und gründlich für 
                    ein Resultat, mit dem du überzeugen kannst.
                </p>
                <span>
                    Mirko, Mitarbeiter
                </span>
            </div>
        </div>
    </div>
</div>

<div class="ctr-lr" id="ctr-4">
    <div class="ctr-lr-1">
        <div class="ctr-img" data-aos="fade-right" data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-offset="320"></div>
        <div class="ctr-info" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <span class="title_1">专业</span>
            <span class="title_2">是我们的精神</span>
            <p>
                REKO提供包括学术论文，动机信，简历，演讲稿等，针对多种文本的校对润色服务。
                专业的团队，由经验丰富的母语润色人员，以及高效的工作技术人员组成，配合一整套完善的REKO用户系统，大幅提高时效性。
                为确保润色的准确性，每一个文本都将由润色人员进行二次审阅。REKO秉承着专业的服务理念，以专业优质的质量、
                高效快速的反应，为用户提供距离更近的服务，以良好的口碑和专业的服务，成为用户在德国求学道路上有力的助手。
            </p>
            <div class="ctr-ponit">
                <div></div>
            </div>
        </div>
    </div>
</div>

<div id="ctr-5">
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <div class="ctr-intro-db">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/db_1.png" class="avatar" data-aos="fade-right" data-aos-duration="800">
            <div class="ctr-info" data-aos="fade-left" data-aos-duration="800">
                <span>清晰</span>
                <p>用户界面简洁明了，信息清晰明确，用户可以快速上手，并熟练操作。</p>
                <span>跟踪</span>
                <p>快速确认，并实时跟进订单的最新进度，以及获取最新资讯(优惠码)，版本更新等重要信息。</p>
            </div>
        </div>
        <div class="ctr-intro-db" id="ctr-intro-db-2">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/db_2.png" class="avatar" data-aos="fade-left" data-aos-duration="800">
            <div class="ctr-info" data-aos="fade-right" data-aos-duration="800">
                <span>上传</span>
                <p>通过拖拽或点击的方式快速上传文档(支持docx、pptx)， 系统将自动识别文档字数或页数， 并给出订单报价。</p>
                <span>效率</span>
                <p>快速创建订单，确认加急选项，并进行便捷支付，系统会在第一时间将文档分配给润色人员，并用邮件告知客户。</p>
            </div>
        </div>
    </div>
</div>

<div class="ctr-lr" id="ctr-6">
    <div class="ctr-lr-1">
        <div class="ctr-img" data-aos="fade-left"  data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-offset="320"></div>
        <div class="ctr-info" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <span class="title_1">温度</span>
            <span class="title_2">是我们的追求</span>
            <p>
                REKO希望成为您的伙伴，从进入REKO起，我们便会时刻伴您左右，客服实时在线，帮助您解决在账户注册、下单支付、文本润色过程中遇到的每一个问题。
                下单后，您可以通过线上系统实时查看上传文档的润色进度，文本校对过程中的每一步进展您都清晰掌握。工作人员收到文档后，会在最大限度保证逻辑通顺的同时，
                突出亮点，优化结构，调整语言错误。在文本润色和订单结束后，对文档的任何疑问，都可以第一时间在客服处寻求答复，
                您还可以通过客服对订单进行反馈和评价，您的评价和建议将成为我们今后改进的方向，而您的鼓励是我们一直前进的最大动力！
            </p>
            <div class="ctr-ponit">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>

<div id="ctr-7">
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <span id="title-l" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="800">流程清晰简单</span>
        <span id="title-r" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="800">独有的用户界面，整套流程清晰简单，只需几次简单的点击，便可以完成从上传到下单的所有步骤。</span>
        <div class="card-process" id="p1" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="600">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_1.svg" alt="">
            <span>上传文本</span>
            <p>选择好需要更改的文本及页面，上传文档</p>
        </div>
        <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_arrow.svg" class="arrow-process" id="a1" alt=""  data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="800">
        <div class="card-process" id="p2" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1000">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_2.svg" alt="">
            <span>调整细节</span>
            <p>选择加急服务(标准、加急等)，确认交付日期</p>
        </div>
        <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_arrow.svg" class="arrow-process" id="a2" alt=""  data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1200">
        <div class="card-process" id="p3" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1400">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_3.svg" alt="">
            <span>确认支付</span>
            <p>添加特殊要求等重要信息，并快捷支付</p>
        </div>

        <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_arrow.svg" class="arrow-process" id="a3" alt="" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1600">
        <div class="card-process" id="p4" data-aos="fade-zoom-in"  data-aos-easing="linear" data-aos-duration="1800">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/icon_process_4.svg" alt="">
            <span>安心等待</span>
            <p>接下来，一切交给我们，是时候放松一下啦!</p>
        </div>
    </div>
</div>

<div class="ctr-lr" id="ctr-8">
    <div class="ctr-lr-1">
        <div class="ctr-img" data-aos="fade-right"  data-aos-easing="ease-in-sine" data-aos-duration="800" data-aos-offset="320"></div>
        <div class="ctr-info" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="800">
            <span class="title_1">安全</span>
            <span class="title_2">是我们的承诺</span>
            <p>
                我们竭尽全力保护你的数据安全，因为没有安全保护措施，隐私就无从谈起。从最初的构想开始，隐私理念就已深植其中。
                我们的网站中含有多项创新的隐私保护科技与技术，旨在最大限度地减少我们或他人所能接触的用户数据；而强大的安全功能则有助于防止他人接触你的信息。
                我们还在不断探索各种新途径，以保障你个人信息的安全。我们还将会在每一次订单结束的30天后将您上传的资料进行处理，避免隐私泄露而带来不必要的损失。
                支付流程更加便捷可靠，支持PayPal、Klarna、银行转账等支付手段。支付成功后，您会得到附有订单详细信息的邮件通知，方便进行售后服务。
            </p>
            <div class="ctr-ponit">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>


<div id="ctr-9">
    <div id="ctr-wrapper"  data-aos="fade"  data-aos-easing="ease-in-sine" data-aos-duration="800">
        <div class="ctr-info">
            <span #counter class="info-nr">{{count_cx | number: '1.0-0'}}</span>
            <span>注册用户</span>
        </div>
        <div class="ctr-info">
            <span class="info-nr">{{count_uni | number: '1.0-0'}}</span>
            <span>专业领域</span>
        </div>
        <div class="ctr-info">
            <span class="info-nr">{{count_file | number: '1.0-0'}}</span>
            <span (click)="update_counter()">润色文档</span>
        </div>
    </div>
</div>


<div id="ctr-10">
    <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/home/bg_6.svg" alt="" data-aos="fade"  data-aos-easing="ease-in-sine" data-aos-duration="800" >
</div>

<div id="ctr-11">
    <div class="ctr-bg"></div>
    <div class="ctr-fg" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="850">
        <div id="ctr-title">
            <div id="icon"></div>
            <span>用户评价</span>
        </div>
        <div id="ctr-cmt">
            <div class="card-cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/4.svg" alt="">
                 <span>ID-3852953</span>
                 <p>
                    用系统下单确实很方便，整体还是比较简洁方便的，中间有点小插曲也都及时的修正了，反馈问题，也能很快的得到答复。能成为网站的参与者，感觉也不错。
                </p>
            </div>
            <div class="card-cmt card-inv">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/2.svg" alt="">
                <span>ID-1946670</span>
                 <p>
                    自己的水平不足以让我有勇气将论文递交，毕竟之前挨过不少的批评，REKO润色十分用心，修改的非常地道，
                    升级的用词用语使之前的文章看起来更高级更具阐述性，客服还给我多申请了一些优惠，比心！
                </p>
            </div>
            <div class="card-cmt card-inv">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/1.svg" alt="">
                <span>ID-6438421</span>
                 <p>
                    我的文章专业性很强，还是比较冷门的专业，下单时还很有顾虑，还在想是否能修改好呢，但是拿到手才发现我是多虑了，
                    果然不愧是专业的润色，专业术语都修改得非常到位，全文修改的非常好，语法，错误的单词，最关键连标点符号都给我改了！
                </p>
            </div>
            <div class="card-cmt" *ngIf="switcher_cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/3.svg" alt="">  
                <span>ID-4549018</span>
                 <p>
                    非常棒！我太满意了，加急2天内给我改了很多东西，把整篇文章的连贯性以及流畅性拉高了，
                    有些让人容易误会的词汇也改更为准确的词汇，价格其实很值当。非常满意的一次润色服务。
                </p>
            </div>
            <div class="card-cmt" *ngIf="switcher_cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/5.svg" alt="">
                <span>ID-7303752</span>
                 <p>
                    很不错，因为是朋友推荐来的，所以我毫不犹豫的选择了这家，润色的很快，润色之后文章读起来舒服通畅，
                    简洁明了不生硬，部分专业性的地方有一点分歧，但是可以也理解为同义不同词。
                </p>
            </div>
            <div class="card-cmt" *ngIf="switcher_cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/6.svg" alt="">
                <span>ID-9375025</span>
                 <p>
                    第一次在网上尝试润色服务，专业细致的做事水平很是打动我，修改的速度很快，而且很贴心的发送邮件，文档是附上修改痕迹及解释的，
                    看到密密麻麻的修改痕迹真的有吓一跳，知道文章存在问题，但是没想到问题那么的多，感谢校对人员的认真态度，给我增添了不少的信心。
                </p>
            </div>
            <div class="card-cmt" *ngIf="switcher_cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/7.svg" alt="">
                <span>ID-9310268</span>
                 <p>
                    客服好沟通，润色的很效率，急着上交也不会耽误事情，这点很好，改出来的效果也不错，
                    词汇和句式比之前更为精炼，文章水平整体都得到了一个好的提升。
                </p>
            </div>
            <div class="card-cmt" *ngIf="switcher_cmt">
                <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/dashboard/avatar/8.svg" alt="">
                <span>ID-3280527</span>
                 <p>
                    如果不在意价格的话，这家店绝对是最好的选择，一开始也抱怨过价格高，但是收到文档的时候就明白了，一切最起码在我看来是值得的，
                    与Ebay那种个人修改，不如找REKO，不花冤枉钱。而且专业机构对比，他家的价格也是市场最低了。
                </p>
            </div>
        </div>
        <div class="btn-cmt" (click)="switcher_cmt = !switcher_cmt" *ngIf="!switcher_cmt"><span>更多评论</span></div>
        <div class="btn-cmt" (click)="switcher_cmt = !switcher_cmt" *ngIf="switcher_cmt"><span>收起评论</span></div>
    </div>
</div>

<div id="ctr-12">
    <div class="ctr-bg"></div>
    <div class="ctr-fg">
        <span id="title-1" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="800">多种方案供你选择</span>
        <span id="title-2" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-duration="800">我们精心准备了多种方案和最优的价格，满足您对润色的一切需求。</span>
        <div class="ctr-card">
            <div class="card-preis" id="preis-1" data-aos="fade-up"  data-aos-duration="800">
                <span class="card-title-1">论文作业</span>
                <span class="card-title-2">5.49€/页</span>
                <span class="card-category">毕业论文</span>
                <span class="card-category">家庭作业</span>
                <span class="card-category">实习报告</span>
            </div>
            <div class="card-preis" id="preis-1" data-aos="fade-up" data-aos-duration="1200">
                <span class="card-title-1">申校求职</span>
                <span class="card-title-2">16.49€/份</span>
                <span class="card-category">求职信</span>
                <span class="card-category">动机信</span>
                <span class="card-category">个人简历</span>
            </div>
            <div class="card-preis" id="preis-1" data-aos="fade-up" data-aos-duration="1600">
                <span class="card-title-1">会议演讲</span>
                <span class="card-title-2">16.49€/份</span>
                <span class="card-category">演讲文稿</span>
                <span class="card-category">商业文案</span>
                <span class="card-category">会议发言</span>
            </div>
            
        </div>
    </div>
</div>

<div id="ctr-13">
    <div class="ctr-wrapper">
        <span id="title-1">做最完美的事</span>
        <span id="title-2">想要尽快获得更多资讯，开启你的文本润色之旅，
            以及完整的用户体验，点击快速注册，数据安全，绿色无广告
        </span>
        <div [routerLink]="['/signup']">
            <span>快速注册</span>
        </div>
    </div>
</div>