<div class="ctr">
    <div id="impressum">
        <h1>Impressum</h1>
        <h2>Angaben gemäß § 5 TMG</h2>
        <span>REKO UG (haftungsbeschränkt)</span>
        <span>Borkumstr. 9B</span>
        <span>26603 Aurich</span>
        <span>Geschäftsführung: Katharina Wen</span>
        <h2>Kontakt</h2>
        <span>Telefon: +49163/1156839</span>
        <span>E-Mail: service@ireko.de</span>
    </div>
</div>

<div id="ctr-13">
    <div class="ctr-wrapper">
        <span id="title-1">做最完美的事</span>
        <span id="title-2">想要尽快获得更多资讯，开启你的文本润色之旅，
            以及完整的用户体验，点击快速注册，数据安全，绿色无广告
        </span>
        <div [routerLink]="['/signup']">
            <span>快速注册</span>
        </div>
    </div>
</div>