<div class="ctr">
    <div class="ctr-1">
        <div class="ctr-2" id="ctr-2-1">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/auth/figure.svg">
        </div>
        <div class="ctr-2" id="ctr-2-2">
            <img src="https://s3.eu-central-1.amazonaws.com/reko.assets/img/logo/logo_blue.svg"  [routerLink]="['..']">
        </div>
        <div class="ctr-2" id="ctr-2-3">
            <h1>让德语更有温度</h1>
        </div>
        <div class="ctr-2" id="ctr-2-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>