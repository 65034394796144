import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators} from '@angular/forms';

import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import { DashboardService } from '../dashboard.service'
import { PreOrder } from '../dashboard.interface'
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component'
import { DialogPaymentComponent } from './dialog-payment/dialog-payment.component'


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  doc_uploaded: boolean = false;
  doc_uploading: boolean = false;
  doc_cat_checked: boolean = false;
  is_paying: boolean = false;
  dialogConfirm: MatDialogRef<any>;
  dialogPayment: MatDialogRef<any>;
  dialogUpdate: MatDialogRef<any>;

  doc: File;
  doc_extension:string;
  preorder: PreOrder = {} as any;
  doc_availability = {}  


  catalogFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  catalog: any [] = [
    {value: '1', viewValue: '学术论文', disabled_pptx: true},
    {value: '2', viewValue: '家庭作业', disabled_pptx: true},
    {value: '3', viewValue: '实习报告', disabled_pptx: true},
    {value: '4', viewValue: '个人简历', disabled_pptx: true},
    {value: '5', viewValue: '动机信', disabled_pptx: true},
    {value: '6', viewValue: '演讲稿', disabled_pptx: false},
    {value: '7', viewValue: '其他类型', disabled_pptx: false},
  ];

  class_inkbar_list: string[] =  ["inkbar-normal", "inkbar-express", "inkbar-turbo"]
  class_inkbar =  this.class_inkbar_list[0]
  class_inkbar_button: string[] =  ["button-normal", "button-express", "button-turbo"]
  class_button =  this.class_inkbar_button[0]
  class_tab_icon: string[] =  ["#1C68D9", "#1E94A0", "#FF9757"]
  class_icon =  this.class_tab_icon[0]

  constructor( private _dbService: DashboardService, private _snackBar: MatSnackBar, private _router: Router, public _dialog: MatDialog) { }

  ngOnInit(): void {
    this.initDocAvailability();
  }

  async initDocAvailability () {
    this.preorder.urgency = '1'; // prio tab set to nomral
    this.catalogFormControl.setValue(''); // dropdown list set to default
    this.catalogFormControl.markAsUntouched(); // dropdown list set as untouched
    this.doc_availability = {
      "1": {
        disabled: true,
        date_end: "",
        duration: "",
        amount_customer: "",
      },
      "2": {
        disabled: true,
        date_end: "",
        duration: "",
        amount_customer: "",
      },
      "3": {
        disabled: true,
        date_end: "",
        duration: "",
        amount_customer: "",
      }
    }
  }

  onDocChanged (event:any) {
    this.doc = event.target.files[0];
    if (this.doc) {
      this.uploadFile();
    }
  }

  onDocDropped (event: any) {
    this.doc = event[0];
    if (this.doc) {
      this.uploadFile();
    }
  }

  async uploadFile () {
    this.initDocAvailability();
    this.catalogFormControl.disable();
    this.doc_uploaded = false;
    this.doc_uploading = true;
    this._snackBar.open('文档上传中, 请耐心等待', '', {duration: 2000,  panelClass :['snackbar-success']});

    const data_request = new FormData();
    data_request.append('file_customer', this.doc);

    if (this.doc.size > 20971520)
    {
      this.doc_uploading = false;
      this._snackBar.open("上传文档超过20MB, 请压缩并重新上传", '', {duration: 3000,  panelClass :['snackbar-error']});
    }
    else
    {
      this._dbService.create_preorder(data_request).subscribe(
        result => {
          this.preorder.file_customer = this.doc;
          this.preorder.id = result['id'];
          this.preorder.datetime_joined = result['datetime_joined'];
          this.preorder.file_customer_name = result['file_name_customer'];
          this.preorder.count_pages = result['count_pages'];
          this.preorder.count_words = result['count_words'];
          this.doc_extension = this.preorder.file_customer_name.split('.').pop()
          this._dbService.check_preorder_availability(this.preorder.id).subscribe(
            result => {
              this.doc_uploaded = true;
              this.catalogFormControl.enable();
              this.doc_uploading = false;
              this.doc_availability = result;
              this.setUrgencyAvailability(result);
              this.mapAvailabilityToPreorder("1");
            },
            error => {
              this.doc_uploading = false;
              this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
            }
          )
        },
        error => {
          this.doc_uploading = false;
          console.log("upload error");
          this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
        }
      );
    }
  }

  onCatalogChanged (event:any){
    this.preorder.catalog = event.value.toString();
  }

  setUrgencyAvailability (result) {
    this.doc_availability["2"]["disabled"] = true;
    this.doc_availability["3"]["disabled"] = true;
    if (result["2"]["duration"] < result["1"]["duration"])
    {
      this.doc_availability["2"]["disabled"] = false;
    }
    if (result["3"]["duration"] < result["2"]["duration"])
    {
      this.doc_availability["3"]["disabled"] = false;
    }
  }

  mapAvailabilityToPreorder (urgency: string) {
    this.preorder.amount_customer = this.doc_availability[urgency]['amount_customer'];
    this.preorder.date_end = this.doc_availability[urgency]['date_end'];
    this.preorder.duration = this.doc_availability[urgency]['duration'];
  }

  onUrgencyChanged (event:any) {
    this.preorder.urgency = (event.index + 1).toString();
    this.mapAvailabilityToPreorder(this.preorder.urgency);
    // set css class
    this.class_inkbar =  this.class_inkbar_list[event.index];
    this.class_button = this.class_inkbar_button[event.index];
    this.class_icon = this.class_tab_icon[event.index];
  }


  startPayment () {
    this.is_paying = true;
    let data_request:any = {
      'catalog': this.preorder.catalog,
      'urgency': this.preorder.urgency,
      'date_end': this.preorder.date_end,
    }

    this._dbService.update_preorder(this.preorder.id, data_request).subscribe(
      result => {
        if (result['updated'] == true ) {
          this.openPaymentDialog();
        }
        else {
          this.doc_uploaded = false;
          this.is_paying = false;
          this.catalogFormControl.disable();
          this.initDocAvailability();
          this._snackBar.open("验证失败，工作时段已被其他用户预订，请重新上传文档", '', {duration: 8000,  panelClass :['snackbar-error']});
        }
      },
      error => {
        this.doc_uploaded = false;
        this.is_paying = false;
        this.catalogFormControl.disable();
        this.initDocAvailability();
        this._snackBar.open(error['error']['detail'], '', {duration: 3000,  panelClass :['snackbar-error']});
      }
    )
  }

  openPaymentDialog() {
    const timer_confirm = 3000;

    this.dialogConfirm = this._dialog.open(DialogConfirmComponent, {panelClass: 'custom-dialog-container', disableClose: true });
    
    this.dialogConfirm.afterOpened().subscribe(_ => { setTimeout(() => { 
      this.dialogConfirm.close();
      
      this.dialogPayment = this._dialog.open(DialogPaymentComponent, {
        panelClass: 'custom-dialog-container',
        disableClose: true,
        data: this.preorder
      });

      this.dialogPayment.afterClosed().subscribe(result => {
        if (result != null) {
          this._router.navigate(['dashboard/orders',]);
          this._snackBar.open('订单信息已更新, 工作人员会立即进行确认', '', {duration: 8000,  panelClass :['snackbar-success']});     
        }
        this.is_paying = false;
      });

    }, timer_confirm)});
  }
}
