import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service'
import { Observable, of  } from 'rxjs';
import {  map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _authService: AuthService ){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>{
    if (!!localStorage.getItem("refresh")) {
      return this._authService.jwt_refresh(localStorage.getItem("refresh")).pipe(
        // refresh token is vaild => update access token, return true
        map(
          result => {
            return true
        }
      ),
        // refresh token is invaild
        catchError(
          err => {
            localStorage.clear();
            this._router.navigate(['/signin']);
            return of(false)
        }
      ))
    }
    else {
      localStorage.clear();
      this._router.navigate(['/signin']);
      return of(false)
    }
  }
}

